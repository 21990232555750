import {
  TeamInvitationRes,
  TeamRes,
  TeamMemberRes,
  TeamMemberInvitationRes,
} from 'services/api/schema/team';
import { Page } from './Page';

export interface Team extends TeamRes {}

export interface TeamMember extends TeamMemberRes {}

export type TeamMembers = Page<TeamMember>;

export interface TeamInvitation extends TeamInvitationRes {}

export interface TeamMemberInvitation extends TeamMemberInvitationRes {}

export type TeamMemberInvitations = Page<TeamMemberInvitation>;

export enum ETeamRole {
  teamAdmin = 'teamAdmin',
  teamOwner = 'teamOwner',
  teamMember = 'teamMember',
  billingAdmin = 'billingAdmin',
  communityAdmin = 'communityAdmin',
  microengineAdmin = 'microengineAdmin',
  walletAdmin = 'walletAdmin',
}

export const HAVE_WALLET_ACCESS = [ETeamRole.teamAdmin, ETeamRole.teamOwner, ETeamRole.walletAdmin];

export type TeamRole = keyof typeof ETeamRole;

export interface ITeamValues {
  name: string;
  description: string;
}

export interface IInviteTeamMemberValues {
  name: string;
  email: string;
  force?: boolean;
}

export interface IUpdateTeamMemberValues {
  roles: TeamRole[];
}
