import React from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import { MessageDescriptor } from 'react-intl';
import styles from 'views/styles';
import NavigationLink from './NavigationLink';

interface Props {
  options: {
    id: number;
    to: string;
    hide?: boolean;
    label: MessageDescriptor;
    matches?: string[];
    dataCy?: string;
  }[];
}

const HeaderNav = ({ options }: Props) => (
  <div css={ownStyle}>
    <ul>
      {options
        .filter((option) => !option.hide)
        .map((option) => (
          <li key={option.id}>
            <NavigationLink
              to={option.to}
              label={option.label}
              matches={option.matches}
              dataCy={option.dataCy}
            />
          </li>
        ))}
    </ul>
  </div>
);

const ownStyle = css`
  display: flex;
  flex: 1;
  li {
    display: inline;
  }
  a {
    margin-left: ${styles.spacing.sm};
  }
`;

export default HeaderNav;
