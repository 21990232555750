import { useUser } from 'views/components/providers/UserProvider';
import { contextAccount } from 'state/auth/selectors';
import { store } from 'state/store';

export default function useHasPrivateCommunity() {
  const user = useUser();
  const ctx = contextAccount(store);
  if (ctx?.context === 'team') {
    const team = user.teams.find((t) => t.accountNumber.toString() === ctx.accountNumber);
    return team?.hasPrivateCommunity;
  }
  return false;
}
