import React, { useState } from 'react';
import TableRow from '@material-ui/core/TableRow';

interface IHoverableTableRow {
  className?: string;
  dataCy?: string;
  onClick?: () => void;
  renderCells: (params: { isHovering: boolean }) => any;
}

/**
 * Wrapper table row component for passing down the hover state for easier reuse.
 */
const HoverableTableRow = ({ className, dataCy, renderCells, onClick }: IHoverableTableRow) => {
  const [isHovering, setIsHovering] = useState(false);

  const _onClick = () => typeof onClick === 'function' && onClick();

  return (
    <TableRow
      className={className}
      hover
      data-cy={dataCy}
      onClick={_onClick}
      onMouseMove={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onBlur={() => setIsHovering(false)}>
      {renderCells({ isHovering })}
    </TableRow>
  );
};

export default HoverableTableRow;
