import React, { useState } from 'react';
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { uniqBy } from 'lodash';
import styled from '@emotion/styled';
import { TableCell, Tooltip } from '@material-ui/core';
import { defineMessages, useIntl } from 'react-intl';
import Checkbox from '@material-ui/core/Checkbox';
import Popover from 'views/components/Popover';
import { ApiKeyData } from 'models/ApiKey';
import styles from 'views/styles';
import Icon from 'views/components/Icon';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { Row } from 'views/components/table/CustomTable';
import CustomTable from 'views/components/table/CustomTable';
import CustomTableHead from 'views/components/table/CustomTableHead';
import ApiKeyTableRow from './ApiKeyTableRow';

interface IApiKeyTableProps {
  results: ApiKeyData[];
  archiveApiKey: (id: string, key: string) => void;
  team: number;
}

const messages = defineMessages({
  key: {
    id: 'ApiKeyTable.row.key',
    defaultMessage: 'Key Name',
  },
  team: {
    id: 'ApiKeyTable.row.team',
    defaultMessage: 'Team Name',
  },
  name: {
    id: 'ApiKeyTable.row.name',
    defaultMessage: 'Key',
  },
});

const Title = styled.h3`
  font-size: 2rem;
  font-weight: bold;
  margin-left: 3rem;
  margin-bottom: 2rem;
`;

const isNotExpired = (expiration: string | null | undefined, deadline: Date): boolean =>
  !expiration || new Date(expiration) >= deadline;

const ApiKeyTable = ({ results, team, archiveApiKey }: IApiKeyTableProps) => {
  const intl = useIntl();
  const { classes } = useStyles();
  const [filteredTeams, setFilteredTeams] = useState<string[]>([]);

  const teamNames = uniqBy(
    results
      .map((key) => (key.team ? { label: key.team.name, value: key.team.name } : null))
      .filter((key) => key !== null),
    (row) => row?.value
  ) as { label: string; value: string }[];

  const rows = [
    {
      id: 'key',
      numeric: false,
      label: intl.formatMessage(messages.key),
      sortable: false,
    },
    {
      id: 'teamId',
      numeric: false,
      label: intl.formatMessage(messages.team),
      sortable: false,
    },
    {
      id: 'name',
      numeric: false,
      label: intl.formatMessage(messages.name),
      sortable: false,
    },
  ].filter(Boolean) as Row<ApiKeyData>[];

  return (
    <div style={{ margin: '3rem 0', width: '100%' }}>
      <Title>{team ? 'Team' : 'Personal'} Account API Keys</Title>
      <CustomTable<ApiKeyData>
        rows={results.filter((apiKey) => {
          if (filteredTeams.length === 0) {
            return true;
          }
          return filteredTeams.includes(apiKey.team?.name || '');
        })}
        renderTableHead={() => (
          <CustomTableHead
            rows={rows}
            showActions={true}
            renderHeaderCell={(row) => {
              if (!team && row.id === 'teamId') return <TableCell key={row.id}></TableCell>;
              return (
                <TableCell key={row.id} align={row.numeric ? 'right' : 'left'}>
                  <div css={classes.cellContainer}>
                    {row.label}
                    {row.id === 'teamId' && teamNames.length > 0 && (
                      <Popover
                        placement='bottom-start'
                        button={
                          <Tooltip title='Filter by team' placement='top'>
                            <div css={classes.iconContainer}>
                              <Icon
                                css={[classes.icon, filteredTeams.length && classes.iconActive]}
                                name='dropdown'
                              />
                            </div>
                          </Tooltip>
                        }
                        content={
                          <div css={classes.popover}>
                            <div
                              style={{ height: `${teamNames.length * 4.75}rem` }}
                              css={classes.listColumns}>
                              {teamNames.map((item) => (
                                <div css={classes.pane} key={item.label}>
                                  <label>
                                    <Checkbox
                                      color='primary'
                                      checked={filteredTeams.includes(item.value)}
                                      onChange={(_e) => {
                                        filteredTeams.includes(item.value)
                                          ? setFilteredTeams(
                                              filteredTeams.filter((role) => role !== item.value)
                                            )
                                          : setFilteredTeams([...filteredTeams, item.value]);
                                      }}
                                    />
                                    {item.label}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>
                        }
                      />
                    )}
                  </div>
                </TableCell>
              );
            }}
          />
        )}
        renderTableRow={(row: ApiKeyData) => {
          // Only allow to delete keys when there is at least
          // one no expirable or not expired key in a team, same
          // for user's keys
          let archiver: ((id: string, key: string) => void) | null = null;
          const now = new Date();
          const notExpiredKeys = results.filter((r) => isNotExpired(r.expiration, now));
          if (team) {
            if (results.filter((r) => r.team?.id === row.team?.id).length > 1) {
              archiver = archiveApiKey;
            }
          } else if (notExpiredKeys.length > 1) {
            archiver = archiveApiKey;
          }
          return (
            <ApiKeyTableRow key={row.key} rowData={row} team={team} archiveApiKey={archiver} />
          );
        }}
      />
    </div>
  );
};

const useStyles = makeStyles({
  base: {
    iconContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },
    cellContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',
    },
    icon: {
      fontSize: '0.6rem',
    },
    iconConfig: {
      width: '2.5rem',
      height: '2.5rem',
      marginTop: '1rem',
      marginLeft: '2rem',
      marginRight: '2rem',
      cursor: 'pointer',
    },
    popover: {
      padding: '2rem 5rem 2rem 2rem',
      borderRadius: '0.5rem',
      marginTop: '1rem',
      fontWeight: 600,
      border: `1px solid ${styles.color.lightGrey}`,
    },
    listColumns: {
      marginTop: '1rem',
    },
    pane: {
      '&:hover': {
        cursor: 'grab',
      },
    },
  },
  light: {
    icon: {
      color: styles.color.black,
    },
    iconActive: {
      color: styles.color.purple,
    },
    iconConfig: {
      color: styles.color.purple,
    },
    popover: {
      backgroundColor: styles.color.ulGrey,
    },
  },
  dark: {
    icon: {
      color: styles.color.greyishPurple,
    },
    iconActive: {
      color: styles.color.lightBlue,
    },
    iconConfig: {
      color: styles.color.lightBlue,
    },
    popover: {
      backgroundColor: styles.color.darkOffBlack,
    },
  },
});

export default ApiKeyTable;
