// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import Button from '@material-ui/core/Button';
import api from 'services/api';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import reduce from 'lodash/reduce';
import snakeCase from 'lodash/snakeCase';
import { useUser } from 'views/components/providers/UserProvider';
import { ETeamRole } from 'models/Team';
import { store } from 'state/store';
import { contextAccount } from 'state/auth/selectors';
import { toISODate, daysFromNowDate } from 'utils/date/date';
import usePagination from 'hooks/usePagination';
import TeamActivityTableContainer from './TeamActivityTable/TeamActivityTableContainer';
import MetadataInputContainer from '../TeamTeamActivityTab/MetadataInputContainer';
import useTeamActivity from './TeamActivityTable/hooks/useTeamActivity';
import ActionPopover from 'components/ActionPopover';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import useFormBuilder from 'components/FormBuilder';

const messages = {
  eventType: 'Event Type',
  user: 'User',
  timeStamp: 'Timestamp',
  metaData: 'Metadata',
  startDate: 'Start',
  endDate: 'End',
  metadataKey: 'Metadata Key',
  metadataValue: 'Metadata Value',
};
type FilterProps = {
  userAccountId?: string | number;
  eventType: string;
  startDate: DateSelectorProps;
  endDate: DateSelectorProps;
  [key: string]: string;
};

type DateSelectorProps = {
  option: 'DAYS' | 'TODAY';
  value: number | '';
};

type Days = 'TODAY' | string | {};

function daysToDate(days: Days) {
  if (days.toUpperCase() === 'TODAY') {
    const today = toISODate(new Date());
    return today;
  }
  const daysNumber = parseInt(days.replace(' days', ''), 10);
  return toISODate(daysFromNowDate(-daysNumber));
}

const FILTER_DEFAULT_VALUES: FilterProps = {
  eventType: 'Any',
  startDate: {
    option: 'DAYS',
    value: 5,
  },
  endDate: {
    option: 'TODAY',
    value: '',
  },
};

const isThereAnyFilter = (filters: FilterProps, defaultValues: Partial<FilterProps>) => {
  const defaults = { ...FILTER_DEFAULT_VALUES, ...defaultValues };
  return Object.entries(filters).some(
    ([key, value]) => value !== defaults[key as keyof FilterProps]
  );
};

const getMetaValue = (data, key, isCustom) => {
  const [, metaIndex] = key.split('-');
  if (isCustom) {
    const customKey = data[`metaCustomKey-${metaIndex}`];
    const customValue = data[`metaCustomValue-${metaIndex}`];
    return { metaKey: customKey, metaValue: customValue };
  } else {
    const metaValue = data[`metaValue-${metaIndex}`];
    return { metaKey: key, metaValue };
  }
};

const mapFilters = (data: FilterProps, members = [], isBubble = false) => {
  const mappedData = reduce(
    data,
    (acc, value, key) => {
      if (key === 'eventType') {
        if (isBubble) {
          return { ...acc, 'event type': value === 'Any' ? null : value };
        }

        return { ...acc, event_type: value === 'Any' ? null : snakeCase(value.toLowerCase()) };
      }
      if (key === 'userAccountId') {
        const [member = {}] = members.filter((member) => value.includes(member?.email));

        if (isBubble) {
          return { ...acc, user: value === 'Any' ? null : `${member?.name} <${member?.email}>` };
        }

        return { ...acc, user_account_id: value === 'Any' ? null : member?.accountNumber };
      }
      if (['startDate', 'endDate'].includes(key)) {
        if (value.option === 'TODAY') {
          return { ...acc, [key]: daysToDate(`${value.option}` as Days) };
        }

        return {
          ...acc,
          [key]: daysToDate(`${value.value} ${value.option.toLowerCase()}` as Days),
        };
      }

      // metadata
      if (value !== 'Custom') {
        const { metaValue } = getMetaValue(data, key);
        if (value === 'Hash') {
          return { ...acc, sha256: metaValue };
        }
        if (value === 'Filename') {
          return { ...acc, filename: metaValue };
        }
        if (value === 'Sandbox Task ID') {
          if (isBubble) {
            return { ...acc, 'Sandbox Task Id': metaValue };
          }

          return { ...acc, sandbox_task_id: metaValue };
        }
        if (value === 'Instance ID') {
          if (isBubble) {
            return { ...acc, 'Instance Id': metaValue };
          }

          return { ...acc, instance_id: metaValue };
        }
        if (value === 'Community') {
          if (isBubble) {
            return { ...acc, Community: metaValue };
          }

          return {
            ...acc,
            [value.toLowerCase()]: metaValue === 'Any' ? null : metaValue.toLowerCase(),
          };
        }
        if (value === 'Source') {
          if (isBubble) {
            return { ...acc, Source: metaValue };
          }

          return {
            ...acc,
            [value.toLowerCase()]: !!metaValue
              ? metaValue === 'Any'
                ? null
                : metaValue === 'Portal'
                ? 'portal-backend'
                : 'internal'
              : null,
          };
        }
      }

      if (value === 'Custom') {
        const { metaKey, metaValue } = getMetaValue(data, key, true);
        return { ...acc, [metaKey]: metaValue };
      }
      return acc;
    },
    {}
  );
  return mappedData;
};

const EVENT_TYPE_LIST = [
  'All Scans',
  'Download',
  'Hash Search',
  'Historical Hunt',
  'Hunting Yara Rules',
  'IOC User',
  'Metadata Search',
  'Scan',
  'Scan Submit',
  'Sandbox Create',
  'Sandbox Request',
  'Sandbox Submit',
  'Team User Creation',
  'Team User Deletion',
  'Team User Role Edition',
];

const filtersToList = (filters: FilterProps) => {
  return Object.entries(filters)
    .filter(([key, value]) => {
      return value !== '' || value !== undefined || value !== 'Any' || value !== null;
    })
    .map(([key, value]) => {
      return { key, value };
    });
};

export const TeamTeamActivityTab = () => {
  const user = useUser();
  const { accountNumber = 0 } = contextAccount(store);
  const [members, setMembers] = useState([]);
  const [isOpenFilter, setOpenFilter] = useState(false);
  const { classes } = useStyles();
  const teamActivity = useTeamActivity(mapFilters(FILTER_DEFAULT_VALUES));
  const [filters, setFilters] = useState(FILTER_DEFAULT_VALUES);
  const teamAcitivityPaginationProps = usePagination({
    defaultTotal: teamActivity.data?.length ?? 0,
    defaultOffset: 0,
    defaultLimit: 50,
  });

  useEffect(() => {
    (async () => {
      if (!!accountNumber) {
        const { data } = await api.getTeamMembersByAccount({ fields: 'roles' });
        const { results: members } = data;
        const [teamFound = {}] = user.teams.filter(
          (team) => `${team.accountNumber}` === `${accountNumber}`
        );

        if (!isEmpty(teamFound)) {
          const { roles = [] } = teamFound;
          const isAdmin = roles.some((role) =>
            [ETeamRole.teamAdmin, ETeamRole.teamOwner].includes(role)
          );
          if (isAdmin) {
            setMembers(members);
          } else {
            setMembers([user]);
          }
        }
      }
    })();
  }, [accountNumber, user]);

  useEffect(() => {
    if (
      members.length === 1 &&
      !members[0].hasOwnProperty('roles') &&
      isEqual(filters, FILTER_DEFAULT_VALUES)
    ) {
      setFilters({ ...FILTER_DEFAULT_VALUES, userAccountId: `${user.name} <${user.email}>` });

      teamActivity.refetch(
        mapFilters(
          { ...FILTER_DEFAULT_VALUES, userAccountId: `${user.name} <${user.email}>` },
          members
        )
      );
    }
  }, [filters, teamActivity, user.name, user.email, members]);

  const { FormComponent } = useFormBuilder([
    {
      elementType: 'select',
      name: messages.eventType,
      id: 'eventType',
      defaultValue: 'Any',
      defaultOptions: ['Any'].concat(EVENT_TYPE_LIST),
    },
    {
      elementType: 'select',
      name: messages.user,
      id: 'userAccountId',
      defaultValue:
        members.length === 1 && !members[0].hasOwnProperty('roles')
          ? `${user.name} <${user.email}>`
          : 'Any',
      defaultOptions:
        members.length === 1 && !members[0].hasOwnProperty('roles')
          ? [`${user.name} <${user.email}>`]
          : ['Any'].concat(members.map((member) => `${member.name} <${member.email}>`)),
    },
    {
      name: messages.timeStamp,
      combine: [
        {
          elementType: 'dateSelect',
          name: messages.startDate,
          id: 'startDate',
          defaultValue: { value: 5, option: 'DAYS' },
        },
        {
          elementType: 'dateSelect',
          name: messages.endDate,
          id: 'endDate',
          defaultValue: { value: '', option: 'TODAY' },
        },
      ],
    },
  ]);

  /*
   * event_type
   * startDate
   * endDate
   * user_account_id
   * sha256
   * filename
   * sandbox_task_id
   * instance_id
   * community
   * source
   */
  const onSubmit = async (data) => {
    const mappedData = mapFilters(data, members);

    teamActivity.refetch(mappedData);
    setFilters(data);
  };

  const mappedFilters = mapFilters(filters, members);
  const prettiedFilters = filtersToList(mapFilters(filters, members, true));

  return (
    <div>
      <div css={classes.header}>
        <h1 className='h4'>Team Activity</h1>
        <ActionPopover
          onToggle={() => setOpenFilter(true)}
          onClose={() => setOpenFilter(false)}
          isOpen={isOpenFilter}
          name='Filter'
        >
          <FormComponent
            filters={filters}
            onSubmit={(formData) => {
              onSubmit(formData);
              setOpenFilter(false);
            }}
            customInputs={MetadataInputContainer}
            footerRender={({ resetForm, dirtyFields }) => {
              return (
                <div css={classes.buttonsContainer}>
                  <Button
                    onClick={() => {
                      resetForm();
                      teamActivity.reset();
                      onSubmit(FILTER_DEFAULT_VALUES);
                    }}
                    size='small'
                    variant='text'
                    color='primary'
                    css={classes.button}
                  >
                    Reset
                  </Button>
                  <Button
                    type='submit'
                    size='small'
                    variant='contained'
                    color='primary'
                    disabled={!isThereAnyFilter(dirtyFields)}
                    css={classes.button}
                  >
                    Search
                  </Button>
                </div>
              );
            }}
          />
        </ActionPopover>
      </div>
      <TeamActivityTableContainer
        filters={mappedFilters}
        prettiedFilters={prettiedFilters}
        toggleFilters={() => setOpenFilter(true)}
        paginationProps={teamAcitivityPaginationProps}
        teamActivityProps={teamActivity}
      />
    </div>
  );
};

const useStyles = makeStyles({
  base: {
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '1rem 3rem',
    },
    filterButton: {
      padding: '0.8rem 0rem !important',
    },
    buttonsContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '1rem',
    },
    button: {
      padding: '0.7rem 2rem !important',
    },
  },
  light: {},
  dark: {},
});
