import React, { useState, useEffect, useMemo, ReactNode } from 'react';
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import styled from '@emotion/styled';
import Tab from '@material-ui/core/Tab';
import Tabs, { TabsProps as MuiTabsProps } from '@material-ui/core/Tabs';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import Icon from 'views/components/Icon';
import { Tooltip } from '@material-ui/core';

type TabItem = {
  value: string;
  label: string;
  dataTestId?: string;
  disabled?: boolean;
  disabledText?: string;
  active?: boolean;
  className?: string;
  hidden?: boolean;
};

interface PaginatedTabsProps extends MuiTabsProps {
  tabs: TabItem[];
  children?: ReactNode;
  maxTabs?: number;
  minTabs?: number;
}

const StyledTabs = styled(Tabs)`
  .MuiTab-root {
    width: 13rem !important;
  }
`;

const getTabsNumber = ({
  maxTabs,
  minTabs,
  width,
}: {
  maxTabs: number;
  minTabs: number;
  width: number;
}) => {
  if (width > 2100) {
    return maxTabs;
  } else if (width > 1650 && minTabs < 4) {
    return 3;
  } else if (width > 1000) {
    return 2;
  } else {
    return minTabs;
  }
};

const PaginatedTabs = ({
  tabs,
  children,
  maxTabs = tabs.length,
  minTabs = 1,
  ...params
}: PaginatedTabsProps) => {
  const { theme, classes } = useStyles();
  const [visibleTabs, setVisibleTabs] = useState(
    getTabsNumber({
      maxTabs,
      minTabs,
      width: window.innerWidth,
    })
  );
  const [offset, setOffset] = useState(
    Math.min(
      Math.max(
        tabs.findIndex((t) => t.value === params.value),
        0
      ),
      Object.keys(tabs).length - maxTabs
    )
  );
  const filteredTabs = tabs.filter((tab) => !tab.hidden);
  const tabsRow =
    filteredTabs.length > visibleTabs
      ? filteredTabs.slice(offset, offset + visibleTabs)
      : filteredTabs;

  useEffect(() => {
    const handleResize = () => {
      setVisibleTabs(
        getTabsNumber({
          maxTabs,
          minTabs,
          width: window.innerWidth,
        })
      );
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [maxTabs, minTabs]);

  const TabsComponent = useMemo(() => {
    return filteredTabs.length > visibleTabs ? StyledTabs : Tabs;
  }, [filteredTabs.length, visibleTabs]);

  useEffect(() => {
    setOffset(
      Math.min(
        Math.max(
          tabs.findIndex((t) => t.value === params.value),
          0
        ),
        Object.keys(tabs).length - maxTabs
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.value, tabs]);

  return (
    <TabsComponent {...params}>
      {filteredTabs.length > visibleTabs && (
        <div
          data-disabled={offset === 0 ? 'true' : 'false'}
          onClick={() => setOffset(Math.max(0, offset - 1))}
          css={classes.arrow}
        >
          <Icon css={classes.arrowLeft} name='arrow' />
        </div>
      )}
      {tabsRow.map((tab) =>
        tab.disabled ? (
          <Tooltip title={tab.disabledText ?? `No ${tab.label} data`} placement='bottom'>
            <Tab
              key={tab.label}
              value={tab.value}
              label={tab.label}
              data-test-id={tab.dataTestId}
              className={tab.className}
              disabled={tab.disabled}
              style={{
                color: styles.color.xLightGrey,
                borderBottom: tab.active ? `2px solid ${styles.color.xLightGrey}` : 'inherit',
              }}
            />
          </Tooltip>
        ) : (
          <Tab
            style={{
              color: theme === 'light' ? styles.color.purple : styles.color.greyishPurple,
            }}
            key={tab.label}
            value={tab.value}
            label={tab.label}
            data-test-id={tab.dataTestId}
          />
        )
      )}
      {tabs.length > visibleTabs && (
        <div
          data-disabled={offset === filteredTabs.length - visibleTabs ? 'true' : 'false'}
          onClick={() => setOffset(Math.min(filteredTabs.length - visibleTabs, offset + 1))}
          css={classes.arrow}
        >
          <Icon css={classes.arrowRight} name='arrow' />
        </div>
      )}
      {children}
    </TabsComponent>
  );
};

const useStyles = makeStyles({
  base: {
    arrowRight: {
      width: '1.8rem !important',
      transform: 'rotate(-90deg)',
      marginLeft: '-3.4rem',
      marginTop: '-0.6rem',
    },
    arrowLeft: {
      width: '1.8rem !important',
      transform: 'rotate(90deg)',
      marginTop: '-0.6rem',
    },
    arrow: {
      marginTop: '-0.8rem',
      padding: '0 2rem',
      cursor: 'pointer',
      display: 'flex',
      '&[data-disabled="true"]': {
        color: styles.color.grey,
        cursor: 'not-allowed',
      },
    },
  },
  light: {
    arrow: {
      color: styles.color.purple,
    },
  },
  dark: {
    arrow: {
      color: styles.color.blue,
    },
  },
});

export default PaginatedTabs;
