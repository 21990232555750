import React from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import styles from 'views/styles';
import FooterNav from './FooterNav';
import tenant from 'tenants';
import eu from 'assets/images/eu.svg';
import us from 'assets/images/us.svg';

interface Props {
  className?: string;
}

const region = process.env.REACT_APP_REGION as 'us' | 'eu';

const Footer = ({ className }: Props) => (
  <footer className={className} css={style.root}>
    <div className='container'>
      <div className='row'>
        <div className='col-12'>
          <div css={style.content}>
            <div css={style.navigation}>
              <div css={style.flagContainer}>
                {region === 'eu' ? (
                  <img src={eu} alt='EU' css={style.eu} />
                ) : (
                  <img src={us} alt='US' css={style.us} />
                )}
              </div>
              <div css={style.navigation}>
                <FooterNav />
              </div>
            </div>
            <p css={style.copyright}>
              {new Date().getFullYear()} &copy; {tenant.fullName || tenant.name}
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

const style = {
  root: css`
    padding: 2.8rem 0;
  `,
  content: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  navigation: css`
    display: flex;
    align-items: center;
  `,
  copyright: css`
    font-size: ${styles.font.size.p1};
    color: ${styles.color.medLightGrey};
  `,
  flagContainer: css`
    border-radius: 100%;
    overflow: hidden;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  eu: css`
    width: 150%;
    height: 150%;
  `,
  us: css`
    width: 200%;
    height: 150%;
    margin-left: 1.2rem;
  `,
};

export default Footer;
