import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  scan: {
    id: 'header.link.scan',
    defaultMessage: 'Scan',
  },
  communities: {
    id: 'header.link.communities',
    defaultMessage: 'Communities',
  },
  microengines: {
    id: 'header.link.microengines',
    defaultMessage: 'Engines',
  },
  marketplaceStats: {
    id: 'header.link.marketplaceStats',
    defaultMessage: 'Marketplace Stats',
  },
  search: {
    id: 'header.link.search',
    defaultMessage: 'Search',
  },
  hunt: {
    id: 'header.link.hunt',
    defaultMessage: 'Hunt',
  },
  sandbox: {
    id: 'header.link.sandbox',
    defaultMessage: 'Sandbox',
  },
  pricing: {
    id: 'header.link.pricing',
    defaultMessage: 'Pricing',
  },
  settings: {
    id: 'userMenu.dropdown.settings',
    defaultMessage: 'Settings',
  },
  darkThemeOn: {
    id: 'userMenu.dropdown.darkThemeOn',
    defaultMessage: 'Dark Theme: On',
  },
  darkThemeOff: {
    id: 'userMenu.dropdown.darkThemeOff',
    defaultMessage: 'Dark Theme: Off',
  },
  switchAccounts: {
    id: 'userMenu.dropdown.switchAccounts',
    defaultMessage: 'Switch Accounts',
  },
  createTeam: {
    id: 'userMenu.dropdown.createTeam',
    defaultMessage: 'Create team',
  },
  help: {
    id: 'userMenu.dropdown.help',
    defaultMessage: 'Help',
  },
  logout: {
    id: 'userMenu.dropdown.logout',
    defaultMessage: 'Log out',
  },
});
