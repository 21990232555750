import { User } from 'models/User';
import { Team, ETeamRole, TeamRole } from 'models/Team';
import { RootState } from 'state/root';
import { AccountFeatureTag } from 'models/Account';

export const getUserTeamByName = (user: User, name: string) =>
  user.teams.find((t) => t.name === name);

export const getUserTeam = (user: User, team: Team) =>
  user.teams.find((t) => t.accountNumber === team.accountNumber);

export const getUserRolesByTeam = (user: User, team: Team) => {
  const userTeam = getUserTeam(user, team);
  return userTeam ? userTeam.roles : [];
};

export const isUserOfTeamRole = (role: TeamRole, state: RootState) => {
  const { user, team } = state;
  return (
    !!user.profile &&
    !!team.single &&
    getUserRolesByTeam(user.profile, team.single).includes(ETeamRole[role])
  );
};

export const userCanAccessFeature = (feature: AccountFeatureTag, state: RootState) => {
  const { account, user, team } = state;

  if (!account.context || !user.account) {
    return false;
  }

  let features = user.account.features || [];

  if (account.context.team && team.account) {
    features = team.account.features;
  }

  return features.some((f) => f.tag === feature && (f.value || Number(f.baseUses) > 0));
};
