import { UserState } from '.';
import { UserAction, UserActionName } from './types';
import { AuthAction, AuthActionName } from 'state/auth/types';
import { createInitialTimeSeries, buildTimeSeriesData } from 'utils/timeSeries';
import { UserStats, UserStatsData } from 'models/User';
import { nctToEthDenominator } from 'utils/nct';
import calculateGains from 'utils/calculateGains';
import { transformUsageData } from 'state/utils/usage';
import { UserStatsRes } from 'services/api/schema/user';

export const initialUserStats = createInitialTimeSeries<UserStatsData>();

export const initialState: UserState = {
  profile: null,
  account: null,
  usage: [],
  stats: initialUserStats,
};

const user = (state: UserState = initialState, action: UserAction | AuthAction): UserState => {
  switch (action.type) {
    case UserActionName.SET_USER: /* fallthrough */
    case UserActionName.UPDATE_USER: /* fallthrough */
    case UserActionName.GET_USER: {
      if (!action.result) {
        return state;
      }
      return {
        ...state,
        profile: action.result,
      };
    }

    case UserActionName.SET_MULTIFACTOR_AUTH: {
      if (!action.result) {
        return state;
      }
      return {
        ...state,
        profile: {
          ...state.profile!,
          mfaEnabled: action.result.mfaEnabled,
          mfaChanged: action.result.mfaChanged,
        },
      };
    }

    case UserActionName.REFRESH_USER_ACCOUNT: /* fallthrough */
    case UserActionName.GET_USER_ACCOUNT: {
      if (!action.result) {
        return state;
      }
      return {
        ...state,
        account: action.result,
      };
    }

    case UserActionName.GET_USER_USAGE: {
      if (!action.result) {
        return state;
      }
      return {
        ...state,
        usage: transformUsageData(action.result),
      };
    }

    case UserActionName.GET_USER_STATS: {
      if (!action.result) {
        return state;
      }
      const result = buildTimeSeriesData(action.result, action.result.results);
      return {
        ...state,
        stats: transformGains(transformNCT(result)),
      };
    }

    case AuthActionName.LOGOUT: {
      return initialState;
    }

    case UserActionName.ARCHIVE_USER: {
      if (!action.result) {
        return state;
      }
      return initialState;
    }

    case UserActionName.CREATE_CUSTOMER: {
      if (!action.result) {
        return state;
      }

      return {
        ...state,
        account: Object.assign({}, state.account, {
          paymentCustomerId: action.result.id,
        }),
      };
    }

    default: {
      return state;
    }
  }
};

const transformNCT = (stats: UserStatsRes): UserStatsRes => {
  const profitOverTime = stats.results.profitOverTime.map((result) => ({
    ...result,
    value: nctToEthDenominator(result.value),
  }));
  return { ...stats, results: { ...stats.results, profitOverTime } };
};

const transformGains = (stats: UserStatsRes): UserStats => {
  const { rank, rank24hAgo } = stats.results;
  const profitGained = calculateGains(stats.results.profitOverTime);
  let rankGained = null;
  if (rank && rank24hAgo) {
    rankGained = {
      loss: rank24hAgo - rank < 0,
      value: Math.abs(rank - rank24hAgo),
    };
  }
  return { ...stats, results: { ...stats.results, profitGained, rankGained } };
};

export default user;
