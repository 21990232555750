import Button from '@material-ui/core/Button/Button';
import Popover from 'views/components/Popover';
import { makeStyles } from 'views/components/providers/ThemeProvider';

const ActionPopover = ({
  name,
  isOpen,
  children,
  disabled = false,
  onToggle,
  onClose,
}: {
  name: string;
  isOpen: boolean;
  disabled?: boolean;
  children: React.ReactNode;
  onToggle: () => void;
  onClose?: () => void;
}) => {
  const { classes } = useStyles();

  const onClick = () => {
    onToggle && onToggle();
  };

  return (
    <Popover
      isOpen={isOpen}
      onClickAway={onClose}
      placement='bottom-end'
      button={
        <Button
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            event.stopPropagation();
            onClick && onClick();
          }}
          css={classes.button}
          size='small'
          component='button'
          color='primary'
          disabled={disabled}
          variant='outlined'
        >
          {name}
        </Button>
      }
    >
      {children}
    </Popover>
  );
};

const useStyles = makeStyles({
  base: {
    button: {
      padding: '0.7rem 2rem !important',
    },
  },
});
export default ActionPopover;
