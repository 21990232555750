import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { Dispatch } from 'state/types/thunk';
import { ApiKey, ApiKeys } from 'models/ApiKey';
import { useLocation } from 'react-router-dom';
import { openModal } from 'state/modal/actions';
import PanelLoader from 'views/components/Loader/PanelLoader';
import { useDispatch } from 'react-redux';
import { createApiKey, archiveApiKey } from 'state/apiKeys/actions';
import { getMFAData, loginWriteApiKey, removeMFAData } from 'state/auth/actions';
import { CreateApiKeyDialog } from '../ApiKeysTable/CreateApiKeyDialog';
import ApiKeyTable from './ApiKeyTable';

interface IApiKeyTableContainer {
  isMfaEnabled: boolean;
  team?: number | boolean;
  apiKeys: ApiKeys;
  reloadApiKeys: () => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
`;

const ApiKeyTableContainer = ({
  isMfaEnabled,
  team,
  apiKeys,
  reloadApiKeys,
}: IApiKeyTableContainer) => {
  const location = useLocation();
  const dispatch = useDispatch<Dispatch>();
  const teamId = team ? Number(team) : 0;
  const results =
    apiKeys.results && Array.isArray(apiKeys.results)
      ? apiKeys.results.filter((key) => {
          if ((teamId && key.team) || (!teamId && !key.team)) {
            return true;
          }
          return false;
        })
      : null;

  const _createApiKey = async (apiKeyData: Partial<ApiKey>) => {
    if (isMfaEnabled) {
      dispatch(
        loginWriteApiKey({
          location: location.pathname,
          modal: 'CONFIRM_APIKEY_CREATION',
          apiKeyData,
        })
      );
    } else {
      await dispatch(createApiKey(apiKeyData));
      reloadApiKeys();
    }
  };

  const _archiveApiKey = async (id: string, key: string) => {
    if (isMfaEnabled) {
      dispatch(
        loginWriteApiKey({
          location: location.pathname,
          modal: 'CONFIRM_APIKEY_DELETION',
          id,
          key,
        })
      );
    } else {
      await dispatch(archiveApiKey(id));
      reloadApiKeys();
    }
  };

  // Only need to fetch data on mount
  useEffect(() => {
    const mfaStep = getMFAData();
    if (mfaStep) {
      const { modal, apiKeyData, id, key } = mfaStep;
      if (modal === 'CONFIRM_APIKEY_CREATION') {
        dispatch(
          openModal(modal, {
            apiKeyData,
          })
        );
        removeMFAData();
      } else if (modal === 'CONFIRM_APIKEY_DELETION') {
        dispatch(
          openModal(modal, {
            id,
            key,
          })
        );
        removeMFAData();
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!results) return <PanelLoader />;

  return Array.isArray(results) ? (
    <Container>
      <ApiKeyTable results={results} team={teamId} archiveApiKey={_archiveApiKey} />
      <CreateApiKeyDialog createApiKey={_createApiKey} team={teamId} />
    </Container>
  ) : null;
};

export default ApiKeyTableContainer;
