import axios from 'axios';
import { saveAs } from 'file-saver';
import api from 'services/api';
import { Thunk } from 'state/types/thunk';
import request, { NotifyObject } from 'state/utils/request';
import { SubmissionActionName, ClearHashSearchResultsAction } from './types';
import { history } from 'state/store';
import { showNotification } from '../notification/actions';
import { errorMessages, ErrorKeys, reduxErrorBanner } from 'utils/error';
import clickLink from 'utils/clickLink';
import { defineMessages } from 'react-intl';
import { HashType, ESubmissionResultType, NormalizedSearchResult } from 'models/Submission';
import { transformHashSearch } from './reducers';
import { isHash } from 'utils/isHash';
import { SubmissionResultRes } from 'services/api/schema/submission';
import { downloadContent, zipArchive } from 'utils/files';
import { parseScanPageURL } from 'views/url';

const messages = defineMessages({
  rescanFileStart: {
    id: 'scan.rescan.file.start',
    defaultMessage: 'Rescanning the file…',
  },
  rescanUrlStart: {
    id: 'scan.rescan.url.start',
    defaultMessage: 'Rescanning the URL…',
  },
});

const errorKeys: ErrorKeys = {
  rate_limit_exceeded: errorMessages.rateLimit.id,
};

export const getFileSizeLimit = () =>
  request(() => api.getFileSizeLimit().then((res) => res.data.fileSizeLimit), {
    type: SubmissionActionName.GET_FILE_SIZE_LIMIT,
  });

export const submitV2File =
  (
    file: File,
    zip: { isZip: boolean; zipPassword?: string } | undefined,
    isQRCode: boolean | undefined = false,
    callback: (percentage: number) => void
  ): Thunk<Promise<SubmissionResultRes>> =>
  async (dispatch) => {
    // Create cancel token so axios request can be canceled during large file upload
    const source = axios.CancelToken.source();
    dispatch({ type: SubmissionActionName.START_FILE_UPLOAD, cancel: source.cancel });
    return dispatch(
      request(
        async () => {
          // 3 steps upload process
          const { artifactId, uploadUrl } = (await api.createV2File(file.name, zip, isQRCode)).data;
          await api.uploadFile('put', uploadUrl, file, callback, source.token);
          const res = await api.confirmV2File(artifactId);
          return res.data;
        },
        { type: SubmissionActionName.SUBMIT_FILE }
      )
    );
  };

export const cancelFileUpload = (): Thunk => (dispatch, getState) => {
  // Cancel pending axios request
  const { cancelFileUpload } = getState().submission;
  if (cancelFileUpload) {
    cancelFileUpload();
  }
  dispatch({ type: SubmissionActionName.CANCEL_FILE_UPLOAD });
};

export const submitUrl = (url: string) =>
  request(() => api.postUrl(url).then((res) => res.data), {
    type: SubmissionActionName.SUBMIT_URL,
  });

export const getSubmissionUuid = (
  hash: string,
  hashFn: HashType = 'sha256',
  notifyObject?: NotifyObject
) =>
  request(
    () => api.getSubmissionByHash(hash, hashFn).then((res) => res.data),
    { type: SubmissionActionName.GET_SUBMISSION_UUID },
    notifyObject
  );

export const setLoadingState = () => ({
  type: SubmissionActionName.SET_LOADING_STATE,
});

export const getSubmission = (id: string) =>
  request(async () => await api.getSubmission(id).then((res) => res.data), {
    type: SubmissionActionName.GET_SUBMISSION,
  });

export const getSubmissionByHashAndId = (sha256: string, id: string) =>
  request(() => api.getSubmissionByHashAndId(sha256, id).then((res) => res.data), {
    type: SubmissionActionName.GET_SUBMISSION,
  });

export const getSubmissionByUUID = (uuid: string) =>
  request(() => api.getSubmissionByUUID(uuid).then((res) => res.data), {
    type: SubmissionActionName.GET_SUBMISSION,
  });

/**
 * Get submission by hash
 *
 * @param hash - File sha256 value
 */
export const getSubmissionByHash = (hash: string) => {
  return request(
    () => {
      if (!isHash(hash)) {
        return Promise.reject({ message: 'invalid_hash' });
      }
      return api.getSubmissionByHash(hash).then((res) => res.data);
    },
    {
      type: SubmissionActionName.GET_SUBMISSION,
    }
  );
};

/**
 * Get url submission by hash
 *
 * @param hash - File sha256 value
 */
export const getUrlSubmissionByHash = (hash: string) => {
  return request(
    () => {
      if (!isHash(hash)) {
        return Promise.reject({ message: 'invalid_hash' });
      }
      return api.getUrlSubmissionByHash(hash).then((res) => res.data);
    },
    {
      type: SubmissionActionName.GET_SUBMISSION,
    }
  );
};

export const getMetadataByHash = (hash: string) =>
  request(() => api.getMetadataByHash(hash).then((res) => res.data), {
    type: SubmissionActionName.GET_FILE_DETAILS,
  });

export const rescanFile =
  (id: string, hash: string, type: keyof typeof ESubmissionResultType): Thunk<Promise<void>> =>
  async (dispatch) => {
    dispatch({ type: SubmissionActionName.RESCAN_FILE });
    dispatch(
      showNotification({
        status: 'info',
        message:
          type === ESubmissionResultType.URL ? messages.rescanUrlStart : messages.rescanFileStart,
      })
    );
    try {
      const { data } = await api.rescanFile(id);
      dispatch(getSubmission(data.result.id));
      history.replace(
        parseScanPageURL(hash, {
          type: type.toLowerCase() as keyof ESubmissionResultType as Lowercase<
            string & ESubmissionResultType
          >,
        })
      );
    } catch (error) {
      dispatch(
        reduxErrorBanner(
          error,
          errorKeys,
          type === ESubmissionResultType.URL ? errorMessages.rescanUrl : errorMessages.rescanFile
        )
      );
      dispatch({ type: SubmissionActionName.RESCAN_FILE_FAILURE });
    }
  };

export const viewScanResult =
  (hash: string, newTab?: boolean): Thunk<Promise<void>> =>
  async (dispatch) => {
    await api
      .getSubmissionByHash(hash)
      .then((res) => {
        const { type, sha256, id } = res.data.result;
        clickLink(
          parseScanPageURL(sha256, {
            type: type.toLowerCase() as keyof ESubmissionResultType as Lowercase<ESubmissionResultType>,
            instanceId: id,
          }),
          newTab
        );
      })
      .catch(() => {
        dispatch(showNotification({ status: 'failure', message: errorMessages.server }));
      });
  };

export const downloadArtifact =
  (hash: string): Thunk<Promise<void>> =>
  async (dispatch) => {
    try {
      const downloadArtifactFunc = async () => {
        const url = await api.downloadArtifact(hash).then((res) => res.data.url);
        const artifact = await downloadContent(url);
        return { path: hash, buffer: artifact };
      };
      const archive = await zipArchive(`${hash}.zip`, [downloadArtifactFunc]);
      archive.finalize();

      dispatch(
        showNotification({
          status: 'info',
          message:
            'Downloading potentially malicious files is risky. To lessen your risk, we’ve enclosed this artifact in an AES encrypted .zip file with password "infected".',
          delay: 45000,
          isDismissible: true,
        })
      );
    } catch (error) {
      let message;
      switch (error.response?.data.errorType) {
        case 'rate_limit_exceeded':
          message = errorMessages.downloadUsageExhausted;
          break;
        case 'forbidden_download':
          message = errorMessages.downloadForbidden;
          break;
        default:
          message = errorMessages.download;
      }
      dispatch(
        showNotification({
          status: 'failure',
          delay: 10000,
          message,
        })
      );
    }
  };

export const downloadArtifactUrl =
  (hash: string, url: string): Thunk<Promise<void>> =>
  async (dispatch) => {
    try {
      const downloadArtifactUrlFunc = async () => {
        const artifact = await downloadContent(url);
        return { path: hash, buffer: artifact };
      };

      const archive = await zipArchive(`${hash}.zip`, [downloadArtifactUrlFunc]);
      archive.finalize();

      dispatch(
        showNotification({
          status: 'info',
          message:
            'Downloading potentially malicious files is risky. To lessen your risk, we’ve enclosed this artifact in an AES encrypted .zip file with password "infected".',
          delay: 45000,
          isDismissible: true,
        })
      );
    } catch (error) {
      dispatch(
        showNotification({
          status: 'failure',
          message:
            error?.response?.data?.errorType === 'rate_limit_exceeded'
              ? errorMessages.downloadUsageExhausted
              : errorMessages.download,
          delay: 10000,
        })
      );
    }
  };

export const downloadCSVUrl = async (name: string, url: string) => {
  try {
    const csv = await downloadContent(url);

    saveAs(new Blob([csv], { type: 'text/csv;charset=utf-8;' }), `${name}.csv`);
  } catch (error) {
    throw error;
  }
};

export const clearHashSearchResults = (): ClearHashSearchResultsAction => ({
  type: SubmissionActionName.CLEAR_HASH_SEARCH_RESULTS,
});

export const getHashSearchResults = (
  hashes: string[],
  page: number,
  limit: number,
  refresh?: boolean
) =>
  request(
    () =>
      Promise.all(
        hashes
          .slice(limit * page, limit * (page + 1))
          .filter((hash) => !!hash)
          .map((hash) => {
            if (!isHash(hash)) {
              return Promise.resolve({
                hash,
                error: { message: 'invalid_hash' },
              } as NormalizedSearchResult);
            }

            return api
              .getSubmissionByHash(hash)
              .then((res) => {
                return transformHashSearch(hash, res.data.result);
              })
              .catch((error) => ({ hash, error } as NormalizedSearchResult));
          })
      ).then((results) => results),
    {
      type: refresh
        ? SubmissionActionName.REFRESH_HASH_SEARCH_RESULTS
        : SubmissionActionName.GET_HASH_SEARCH_RESULTS,
      page,
    }
  );
