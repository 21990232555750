import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { translateError, ErrorKeys } from 'utils/error';
import { loadingSelector, errorSelector } from 'state/requests/selectors';
import { ModalState } from 'state/modal';
import { ApiKeyForm } from 'models/ApiKey';
import { closeModal, openModal } from 'state/modal/actions';
import { RootState } from 'state/root';
import ConfirmModal from 'views/components/Modal/ConfirmModal';
import { createApiKey, getApiKeys } from 'state/apiKeys/actions';
import { ApiKeysActionName } from 'state/apiKeys/types';
import config from 'config';

interface ConfirmApiKeyCreationParams {
  apiKeyData: Partial<ApiKeyForm>;
}

const messages = defineMessages({
  textPersonalKey: {
    id: 'account.createApiKey.account.confirm.textPersonalKey',
    defaultMessage: 'Confirm creation of a personal API Key?',
  },
  textTeamKey: {
    id: 'account.createApiKey.account.confirm.textTeamKey',
    defaultMessage: 'Confirm creation of a team API Key?',
  },
  mfaSuccess: {
    id: 'account.createApiKey.account.confirm.success',
    defaultMessage: 'API Key created',
  },
  mfaFailure: {
    id: 'account.createApiKey.account.confirm.failure',
    defaultMessage: 'Error creating API Key. Please try again.',
  },
});

const errorKeys: ErrorKeys = {};

const ConfirmApiKeyCreation = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { requests } = useSelector((state: RootState) => state);
  const isLoading = loadingSelector(requests, [ApiKeysActionName.CREATE_API_KEY]);
  const error = errorSelector(requests, [ApiKeysActionName.CREATE_API_KEY]);

  const { apiKeyData } = useSelector(
    ({ modal }: { modal: ModalState<ConfirmApiKeyCreationParams> }) => modal.params
  );

  return (
    <ConfirmModal
      isLoading={isLoading}
      errorMessage={translateError(intl, errorKeys, error)}
      text={intl.formatMessage(
        apiKeyData?.teamAccountNumber ? messages.textTeamKey : messages.textPersonalKey
      )}
      submitBtnText={intl.formatMessage({ id: 'button.confirm' })}
      onSubmit={async () => {
        await dispatch(createApiKey(apiKeyData));
        dispatch(getApiKeys(undefined, true));
        dispatch(closeModal());
      }}
      onCancel={() => dispatch(closeModal())}
      timeout={parseInt(config.tokenExpirationSec, 10)}
      onTimeout={() => {
        dispatch(closeModal());
        dispatch(openModal('SESSION_EXPIRED'));
      }}
    />
  );
};

export default ConfirmApiKeyCreation;
