import { useState, useEffect, useCallback } from 'react';
import api from 'services/api';

export type ScanHistory = {
  polyscore: number;
  malwareFamily: string;
  timestamp: string;
  id: string;
  hasBeenSandboxed?: boolean;
  resultsAvailable?: number;
};

type Metadata = {
  toolMetadata: {
    malwareFamily: string;
  } | null;
} | null;

const getScanHistory = async (hash: string, offset?: string) => {
  const response = await api.scanHistory(hash, offset);
  const { offset: newOffset, hasMore, result, resultsAvailable } = response.data;
  return {
    offset: newOffset,
    hasMore,
    resultsAvailable,
    data: result.map((data) => {
      const polyuniteMetadata = data.metadata.find(
        (meta) => meta.tool === 'polyunite'
      ) as Partial<Metadata>;
      return {
        polyscore: data.polyscore,
        malwareFamily: polyuniteMetadata?.toolMetadata?.malwareFamily,
        timestamp: data.created,
        id: data.id,
        hasBeenSandboxed: false,
      };
    }) as ScanHistory[],
  };
};

export default function useScanHistory({
  hash,
  shouldFetch,
}: {
  hash: string;
  shouldFetch: boolean;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [more, setMore] = useState<boolean>(true);
  const [availableResults, setAvailableResults] = useState(0);
  const [data, setData] = useState<ScanHistory[]>([]);
  const [offset, setOffset] = useState<string>();

  const fetchScanHistory = useCallback(async () => {
    try {
      setError(false);
      setLoading(true);

      const {
        offset: newOffset,
        hasMore,
        resultsAvailable,
        data,
      } = await getScanHistory(hash, offset);

      setMore(hasMore);
      setOffset(newOffset);
      setAvailableResults(resultsAvailable || data.length);
      setData((prev) => [...prev, ...data]);
      setLoading(false);
    } catch (error) {
      setError(true);
    }
  }, [hash, offset]);

  useEffect(() => {
    if (shouldFetch) {
      fetchScanHistory();
    }
  }, [shouldFetch]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    data,
    more,
    availableResults,
    fetchNextPage: fetchScanHistory,
    loading,
    error,
  };
}
