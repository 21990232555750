import React, { useEffect, useState } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { FormattedMessage } from 'react-intl';
import formatBytes from 'utils/formatBytes';
import { Artifact } from 'models/Submission';
import styles from 'views/styles';
import Icon from 'views/components/Icon';
import Card from 'views/components/layout/Card';
import SidebarDetail from 'views/components/layout/Sidebar/SidebarDetail';
import Loader from 'views/components/Loader';
import StatusIcon from 'views/components/StatusIcon';
import SidebarFileDetails from './SidebarFileDetails';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { Filter } from 'views/components/utils';
import { PolyScore } from 'views/components/PolyScore';
import { InfoTooltip } from 'views/components/InfoTooltip';
import { Toggle } from 'views/components/Toggle';
import { truncateFloat } from 'utils/conversions/conversions';
import { usePivoting } from 'views/components/providers/PivotingProvider';
import { toISODateTime } from 'utils/date/date';

interface IScanResultsSidebar {
  totalPossibleAssertions: number;
  artifact: Artifact;
  isScanning: boolean;
  isLoading?: boolean;
  numMalicious: number;
  numFailed: number;
  totalScans: number;
  errorMessage?: string;
  showDetails?: boolean;
  onShowDetailsChange?: (showDetails: boolean) => void;
}

const messages = {
  polyscore: 'PolyScore™',
  scanDone: 'Engines reported malicious',
  polyscoreDetails: 'PolyScore info',
  polyscoreDescription:
    "PolyScore™ is a threat scoring algorithm that provides the probability a given file contains malware. It weights engine's opinions based on historical performance, known biases, malware family and other threat indicators, summarizing them into a single, authoritative score with context.",
};

type ResultsViewState =
  | 'error'
  | 'notScanned'
  | 'scanning'
  | 'malicious'
  | 'maliciousUrl'
  | 'benign'
  | 'benignUrl'
  | 'failed';

const getResultsViewState = (props: IScanResultsSidebar): ResultsViewState => {
  const {
    isScanning,
    numMalicious,
    numFailed,
    totalScans,
    errorMessage,
    artifact,
    totalPossibleAssertions,
  } = props;
  const notScanned = !isScanning && totalScans === 0;
  const isFailed = totalPossibleAssertions === numFailed;
  const isMalicious = numMalicious > 0;
  const isUrl = artifact.type === 'URL';

  if (errorMessage) {
    return 'error';
  } else if (notScanned) {
    return 'notScanned';
  } else if (isScanning) {
    return 'scanning';
  } else if (isFailed) {
    return 'failed';
  } else if (isMalicious && isUrl) {
    return 'maliciousUrl';
  } else if (isMalicious) {
    return 'malicious';
  } else if (isUrl) {
    return 'benignUrl';
  }
  return 'benign';
};

export const ScanResultsSidebar = (props: IScanResultsSidebar) => {
  const { artifact, showDetails = true, isLoading, onShowDetailsChange } = props;

  const viewState = getResultsViewState(props);
  const [polyuniteMetadata] = artifact
    ? artifact.metadata.filter((item) => item.tool === 'polyunite')
    : [];
  const polyuniteMalwareFamily = polyuniteMetadata?.tool_metadata?.malware_family;

  const { classes } = useStyles();

  if (isLoading)
    return (
      <Card css={classes.root} data-cy='scanSidebar'>
        <Loading />
      </Card>
    );

  return (
    <Card css={classes.root} data-cy='scanSidebar'>
      {showDetails ? (
        <h1 css={[classes.title, viewState === 'notScanned' && classes.mb4]}>Summary</h1>
      ) : null}
      <Filter
        currentState={viewState}
        states={{
          error: <ErrorResult {...props} />,
          notScanned: <NotScannedState />,
          scanning: <ScanningState />,
          failed: <FailedResult {...props} />,
          malicious: <MaliciousResult {...props} />,
          maliciousUrl: <MaliciousUrlResult {...props} />,
          benign: <BenignResult {...props} />,
          benignUrl: <BenignUrlResult {...props} />,
        }}
      />
      {showDetails ? (
        <>
          <SidebarFileDetails
            className='h-pt-xxs h-mb-xs'
            filename={artifact.filename}
            filesize={formatBytes(artifact.size)}
          />
          <div css={[classes.details, classes.pb1]}>
            {polyuniteMalwareFamily && (
              <SidebarDetail
                bold
                center
                css={classes.detail}
                label='PolyUnite family name'
                filter={true}
                value={polyuniteMalwareFamily}
                dataCy='filePolyunite'
              />
            )}
            <span css={classes.date}>{toISODateTime(new Date(artifact.created))}</span>
          </div>
          <div css={classes.details}>
            <SidebarDetail
              css={classes.detail}
              label='SHA-256'
              value={artifact.sha256}
              copy={true}
              monospace={true}
              dataCy='fileSHA256'
            />
            <SidebarDetail
              css={classes.detail}
              label='Scan ID'
              value={artifact.id}
              copy={true}
              monospace={true}
              dataCy='fileScanID'
            />
          </div>
        </>
      ) : (
        <div css={classes.arrowContainer}>
          <button onClick={() => onShowDetailsChange && onShowDetailsChange(true)}>
            <Icon css={classes.icon} name='arrow' />
          </button>
        </div>
      )}
    </Card>
  );
};

export const PolyScoreTooltip = ({ className }: { className?: string }) => {
  return (
    <InfoTooltip
      className={className}
      title={messages.polyscoreDescription}
      aria-label={messages.polyscoreDetails}
    />
  );
};

const PolyScoreHeading = ({ polyscore }: { polyscore?: number | null }) => {
  const { active } = usePivoting();
  const { classes } = useStyles();

  return (
    <div css={classes.headingContainer}>
      <Toggle
        tab='Summary'
        section='Summary'
        itemKey='Polyscore'
        itemActualKey='polyscore'
        operator=' ≥ '
        itemValue={polyscore ? String(truncateFloat(polyscore)) : ''}
      >
        <div css={classes.headingWrap}>
          <p css={[classes.heading, active && classes.headingActive]}>{messages.polyscore}</p>
          <PolyScoreTooltip css={classes.tooltipIcon} />
        </div>
      </Toggle>
    </div>
  );
};

const ScanningState = () => {
  const { classes } = useStyles();
  return (
    <div css={classes.content} data-cy='scanning'>
      <Loader className='h-mb-xs' css={classes.loader} />
      <p className='h-mb-xxs' css={classes.scanning}>
        <FormattedMessage
          id='scan.sidebar.scanning'
          defaultMessage='Scanning in progress. Please wait a moment.'
        />
      </p>
    </div>
  );
};

const Loading = () => {
  const { classes } = useStyles();
  return (
    <div css={classes.content}>
      <Loader className='h-mb-xs' css={classes.loader} />
    </div>
  );
};

const BenignResult = ({ artifact, numMalicious, totalScans }: IScanResultsSidebar) => {
  const { classes } = useStyles();
  return (
    <div css={classes.content} data-testid='benignResult'>
      <PolyScoreHeading polyscore={artifact.polyscore} />
      <PolyScore className='h-mt-tiny h-mb-sm' polyscore={artifact.polyscore} />
      <p css={classes.resultsWrap} className='h-mb-xxs'>
        <span css={classes.results}>
          {numMalicious}/{totalScans}
        </span>{' '}
        {messages.scanDone}
      </p>
    </div>
  );
};

const BenignUrlResult = ({ numMalicious, totalScans }: IScanResultsSidebar) => {
  const { classes } = useStyles();
  return (
    <div css={classes.content} data-testid='benignResult'>
      <StatusIcon className='h-mb-tiny' status='ok' />
      <p css={classes.resultsWrap} className='h-mb-xxs'>
        <span css={classes.results}>
          {numMalicious}/{totalScans}
        </span>{' '}
        {messages.scanDone}
      </p>
    </div>
  );
};

const MaliciousResult = ({ artifact, numMalicious, totalScans }: IScanResultsSidebar) => {
  const { classes } = useStyles();
  return (
    <div css={classes.content} data-testid='maliciousResult'>
      <PolyScoreHeading polyscore={artifact.polyscore} />
      <PolyScore className='h-mt-tiny h-mb-sm' polyscore={artifact.polyscore} />
      <p css={classes.resultsWrap} className='h-mb-xxs'>
        <span css={classes.results}>
          {numMalicious}/{totalScans}
        </span>{' '}
        {messages.scanDone}
      </p>
    </div>
  );
};

const MaliciousUrlResult = ({ numMalicious, totalScans }: IScanResultsSidebar) => {
  const { classes } = useStyles();
  return (
    <div css={classes.content} data-testid='maliciousResult'>
      <StatusIcon className='h-mb-tiny' status='malicious' />
      <p css={classes.resultsWrap} className='h-mb-xxs'>
        <span css={classes.results}>
          {numMalicious}/{totalScans}
        </span>{' '}
        {messages.scanDone}
      </p>
    </div>
  );
};

const FailedResult = ({ artifact }: IScanResultsSidebar) => {
  const { classes } = useStyles();
  return (
    <div css={classes.content} data-cy='failedResult'>
      <PolyScoreHeading polyscore={artifact.polyscore} />
      <PolyScore className='h-mt-tiny h-mb-sm' polyscore={artifact.polyscore} />
      <p className='h-mb-xxs'>
        <FormattedMessage
          id='scan.sidebar.failed'
          defaultMessage='No engines responded to this bounty'
        />
      </p>
    </div>
  );
};

const NotScannedState = () => {
  const { classes } = useStyles();
  return (
    <div css={classes.content} data-cy='notScannedResult'>
      <PolyScoreHeading />
      <PolyScore className='h-mt-tiny h-mb-sm' polyscore={null} />
      <p className='h-mb-xxs'>
        <FormattedMessage id='scan.sidebar.notScanned' defaultMessage='Artifact not yet scanned' />
      </p>
    </div>
  );
};

const ErrorResult = ({ errorMessage }: IScanResultsSidebar) => {
  const { classes } = useStyles();
  return (
    <div css={classes.content} data-cy='errorResult'>
      <StatusIcon className='h-mb-tiny' status='fail' />
      <p className='h-mb-xxs'>{errorMessage}</p>
    </div>
  );
};

const useStyles = makeStyles({
  base: {
    root: {
      padding: styles.spacing.sm,
      position: 'relative',
    },
    nav: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: styles.spacing.xs,
    },
    details: {
      borderTopWidth: 1,
      borderTopStyle: 'solid',
      padding: `${styles.spacing.sm} 0 0`,
    },
    detail: {
      padding: `${styles.spacing.xxs} 0`,
      '&:first-of-type': {
        paddingTop: 0,
      },
      '&:last-child': {
        paddingBottom: 0,
      },
    },
    icon: {
      fontSize: '0.4rem !important',
      color: styles.color.xLightGrey,
      cursor: 'pointer',
    },
    loader: {
      fontSize: '1.1rem !important',
    },
    content: {
      textAlign: 'center',
      position: 'relative',
    },
    scanning: {
      fontWeight: styles.font.weight.bold,
    },
    results: {
      fontWeight: styles.font.weight.bold,
    },
    headingContainer: {
      textAlign: 'left',
      position: 'absolute',
    },
    headingWrap: {
      textAlign: 'left',
    },
    title: {
      fontSize: 24,
      fontWeight: styles.font.weight.bold,
      display: 'inline-block',
      verticalAlign: 'middle',
      marginRight: styles.spacing.tiny,
    },
    heading: {
      fontSize: '1.6rem',
      fontWeight: styles.font.weight.bold,
      display: 'inline-block',
      verticalAlign: 'middle',
      marginRight: styles.spacing.tiny,
    },
    tooltipIcon: {
      fontSize: 5,
      color: styles.color.xLightGrey,
      display: 'inline-block',
      verticalAlign: 'middle',
    },
    pb1: {
      paddingBottom: '2.5rem',
    },
    date: {
      fontSize: '1.4rem',
      textAlign: 'center',
      display: 'block',
    },
    arrowContainer: {
      position: 'absolute',
      bottom: '1.4rem',
      right: '1.4rem',
    },
    mb4: {
      marginBottom: '-4rem',
    },
  },
  light: {
    details: {
      borderTopColor: styles.border.color.grey,
    },
    scanning: {
      color: styles.color.purple,
    },
    heading: {
      color: styles.color.black,
    },
    headingActive: {
      color: styles.color.purple,
    },
    resultsWrap: {
      color: styles.color.black,
    },
  },
  dark: {
    details: {
      borderTopColor: styles.border.color.medDarkPurple,
    },
    scanning: {
      color: styles.color.lightBlue,
    },
    heading: {
      color: styles.color.white,
    },
    headingActive: {
      color: styles.color.lightBlue,
    },
    resultsWrap: {
      color: styles.color.white,
    },
  },
});
