// @ts-nocheck
import React, { useCallback, useEffect } from 'react';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { useForm, Controller, FormProvider, useFormContext } from 'react-hook-form';
import TextField from '@material-ui/core/TextField/TextField';
import Select from 'views/components/Select';
import DateSelector from 'views/components/DateSelector';
import { ObjectInterpolation } from '@emotion/core';
import Switch from '@material-ui/core/Switch';

enum ELEMENT_TYPE {
  select = 'select',
  input = 'input',
  dateSelect = 'dateSelect',
  switch = 'switch',
}

enum DateOptions {
  TODAY = 'TODAY',
  DAYS = 'DAYS',
}

interface IManualItem {
  elementType:
    | keyof typeof ELEMENT_TYPE
    | { name: ELEMENT_TYPE; type: 'text' | 'number' | 'password' };
  name?: string;
  id: string;
  placeholder?: string;
  defaultValue?:
    | string
    | number
    | boolean
    | { [key: string]: string | string[] | number | keyof typeof DateOptions };
  defaultOptions?: string[];
  inputOptions?: { [key: string]: string | number };
}

type CombinedElements<T> = [T, T];

type IManual = IManualItem | { name: string; combine: CombinedElements<IManualItem> };

type FormProps = {
  filters?: {
    [key: string]: string | number | boolean | { value: string | number; option: string };
  };
  onSubmit: (data: any) => void;
  customInputs?: (data: any) => JSX.Element;
  footerRender: (data: any) => JSX.Element;
  onFormDirtyFields?: (data: any) => void;
  customClass?: ObjectInterpolation<any>;
};

const DateSelectorComponent = ({ item }) => {
  const { register, watch, setValue } = useFormContext();
  const dateValue = watch(item.id);

  useEffect(() => {
    register(item.id, { value: dateValue });
  }, [register]); // eslint-disable-line

  const option = dateValue?.option;
  const value = dateValue?.value;

  return (
    <DateSelector
      label={item.name}
      onChange={(value, dateValue) =>
        setValue(item.id, { value, option: dateValue }, { shouldDirty: true })
      }
      maxdays={option === DateOptions.TODAY ? '' : value}
      defaultValue={value}
      defaultOption={option}
    />
  );
};

const TextInputComponent = ({ item }) => {
  const { register, getValues } = useFormContext();

  const { elementType = '' } = getElementProps(item);
  const registerValue = !!elementType && elementType === 'number' ? { valueAsNumber: true } : {};
  const value = getValues()[item.id] ?? '';

  const { ref, ...otherRegistrations } = register(item.id, { value, ...registerValue });

  const valueProps = item.hasOwnProperty('inputOptions')
    ? {
        InputProps: { inputProps: item?.inputOptions },
      }
    : {};

  return (
    <TextField
      inputRef={ref}
      {...otherRegistrations}
      label={item.placeholder}
      type={elementType ?? 'text'}
      fullWidth
      {...valueProps}
    />
  );
};

const SelectComponent = ({ item }) => {
  const { control, getValues } = useFormContext();

  const getOptionDisabled = useCallback(
    (option) => {
      return item?.getOptionDisabled(option, getValues());
    },
    [item, getValues]
  );

  return (
    <Controller
      control={control}
      name={item.id}
      render={({ field }) => {
        return (
          <Select
            {...field}
            getOptionDisabled={item?.getOptionDisabled ? getOptionDisabled : undefined}
            placeholder={item?.placeholder}
            options={item.defaultOptions}
            value={field?.value ?? item.defaultValue}
          />
        );
      }}
    />
  );
};

const SwitchComponent = ({ item }) => {
  const { control } = useFormContext();

  const {
    defaultOptions: [falseLabel, trueLabel],
  } = item;

  return (
    <Controller
      control={control}
      name={item.id}
      render={({ field }) => {
        return (
          <>
            <span>{falseLabel}</span>
            <Switch
              {...field}
              color='primary'
              label={item.name}
              checked={
                (typeof field.value === 'boolean' && field.value) ||
                (typeof field.value === 'string' && field.value === trueLabel)
                  ? true
                  : false
              }
            />
            <span>{trueLabel}</span>
          </>
        );
      }}
    />
  );
};

const getElementProps = (item) => {
  let typeName, typeElement;

  if (!Boolean(item.elementType)) {
    throw Error('Undefined elementType');
  } else if (typeof item.elementType === 'string') {
    typeName = item.elementType;
  } else if (typeof item.elementType === 'object') {
    typeName = item.elementType.name;
    typeElement = item.elementType.type;
  } else {
    throw Error('Wrong data type');
  }
  return { elementName: typeName, elementType: typeElement };
};

const generateComponent = (item, formControl) => {
  const { elementName } = getElementProps(item);

  switch (elementName) {
    case 'input': {
      return () => {
        return <TextInputComponent item={item} />;
      };
    }
    case 'select': {
      return () => {
        return <SelectComponent item={item} />;
      };
    }
    case 'switch': {
      return () => {
        return <SwitchComponent item={item} />;
      };
    }
    case 'dateSelect': {
      return () => {
        return <DateSelectorComponent item={item} />;
      };
    }
    default: {
      throw Error('Unknown element type. Element types allowed: input, select, dateSelect');
    }
  }
};

export const ElementComponent = ({ item }) => {
  const { control } = useFormContext();
  const { classes } = useStyles();

  const Element = React.useMemo(
    () => generateComponent(item, control),
    [item] // eslint-disable-line
  );

  return (
    <div css={classes.singleInputContainer}>
      <Element />
    </div>
  );
};

export const CombineComponent = ({ item }) => {
  const { getValues } = useFormContext();
  const { classes } = useStyles();

  const combineItems =
    typeof item.combine === 'function' ? item.combine(getValues()) : item.combine;

  return (
    <div css={classes.combinedInputContainer}>
      {combineItems.map((item) => {
        return <ElementComponent key={item.id} item={item} />;
      })}
    </div>
  );
};

const getDefaultValues = (items) => {
  return items.reduce((acc, item) => {
    if (item.hasOwnProperty('combine')) {
      const combineItems = typeof item.combine === 'function' ? item.combine() : item.combine;
      const combineValues = getDefaultValues(combineItems);

      return Object.assign(acc, combineValues);
    }

    return Object.assign(acc, { [item.id]: item.defaultValue });
  }, {});
};

const Form = ({
  filters = {},
  manual,
  onSubmit,
  onFormDirtyFields,
  customInputs,
  footerRender,
  customClass,
}: FormProps & {
  manual: IManual[];
}) => {
  const defaultValues = getDefaultValues(manual);
  const filterValues = { ...defaultValues, ...filters };

  const { formState, ...formProps } = useForm({
    defaultValues: filterValues,
  });

  const { classes } = useStyles();

  useEffect(() => {
    onFormDirtyFields && onFormDirtyFields(formState.dirtyFields);
  }, [formState]); // eslint-disable-line

  const CustomInputComponents = React.useCallback((data) => {
    return customInputs(data);
  }, []); // eslint-disable-line

  const FooterComponent = React.useCallback(
    () =>
      footerRender({
        resetForm: formProps.reset,
        dirtyFields: formState.dirtyFields,
      }),
    [formState] // eslint-disable-line
  );

  return (
    <FormProvider formState={formState} {...formProps}>
      <form onSubmit={formProps.handleSubmit(onSubmit)}>
        <div css={customClass ? customClass : classes.container}>
          <div css={classes.innerContainer}>
            {manual.map((item, index) => {
              if (item.hasOwnProperty('combine')) {
                return (
                  <div css={classes.row} key={`parent-combined-${index}`}>
                    <span>{item.name}</span>
                    <CombineComponent key={`combined-${index}`} item={item} />
                  </div>
                );
              }

              return (
                <div css={classes.row} key={`parent-single-${item.id}`}>
                  <span>{item.name}</span>
                  <ElementComponent key={`single-${item.id}`} item={item} />
                </div>
              );
            })}
            {!!customInputs && <CustomInputComponents filters={filters} />}
          </div>
          <FooterComponent />
        </div>
      </form>
    </FormProvider>
  );
};

const useFormBuilder = (manual: IManual[]) => {
  const component = useCallback(
    ({
      filters,
      onSubmit,
      customInputs,
      footerRender,
      onFormDirtyFields,
      customClass,
    }: FormProps) => {
      return (
        <Form
          customClass={customClass}
          manual={manual}
          filters={filters}
          onSubmit={onSubmit}
          onFormDirtyFields={onFormDirtyFields}
          customInputs={customInputs}
          footerRender={footerRender}
        />
      );
    },
    [manual]
  );

  return {
    FormComponent: component,
  };
};

export default useFormBuilder;

const useStyles = makeStyles({
  base: {
    container: {
      borderRadius: '0.5rem',
      padding: '5rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '3rem',
      width: '75rem',
      position: 'relative',
    },
    innerContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '3rem',
      width: '100%',
      maxHeight: '48rem',
      overflowY: 'auto',
      scrollSnapType: 'y proximity',
      '&:after': {
        display: 'block',
        content: '""',
        scrollSnapAlign: 'end',
      },
    },
    row: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      width: '100%',
      '& > span': {
        fontWeight: 600,
      },
    },
    singleInputContainer: {
      width: '45rem',
    },
    combinedInputContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 18,
      width: '45rem',
      justifyContent: 'space-between',
    },
  },
  light: {
    container: {
      background: styles.color.white,
      border: `1px solid ${styles.color.xxLightGrey}`,
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    },
  },
  dark: {
    container: {
      background: styles.color.xxDarkPurple,
      border: `1px solid ${styles.color.xDarkPurple}`,
      boxShadow: '0px 0px 4px rgba(255, 255, 255, 0.15)',
    },
  },
});
