import React from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */

import IconLogo from './icons/IconLogo';
import IconWordmark from './icons/IconWordmark';
import IconGlobe from './icons/IconGlobe';
import IconArrow from './icons/IconArrow';
import IconArrowRight from './icons/IconArrowRight';
import IconArrowSolid from './icons/IconArrowSolid';
import IconDrag from './icons/IconDrag';
import IconBenign from './icons/IconBenign';
import IconMalicious from './icons/IconMalicious';
import IconFail from './icons/IconFail';
import IconQuestion from './icons/IconQuestion';
import IconReload from './icons/IconReload';
import IconCheck from './icons/IconCheck';
import IconCheckAlt from './icons/IconCheckAlt';
import IconClose from './icons/IconClose';
import IconCopy from './icons/IconCopy';
import IconGear from './icons/IconGear';
import IconPower from './icons/IconPower';
import IconProfit from './icons/IconProfit';
import IconLoss from './icons/IconLoss';
import IconMore from './icons/IconMore';
import IconArrowFull from './icons/IconArrowFull';
import IconStar from './icons/IconStar';
import IconAssertions from './icons/IconAssertions';
import IconAccuracy from './icons/IconAccuracy';
import IconToken from './icons/IconToken';
import IconMicroengine from './icons/IconMicroengine';
import IconPlus from './icons/IconPlus';
import IconPlusAlt from './icons/IconPlusAlt';
import IconWarning from './icons/IconWarning';
import IconSuccess from './icons/IconSuccess';
import IconRescan from './icons/IconRescan';
import IconDownload from './icons/IconDownload';
import IconDownloadProgress from './icons/IconDownloadProgress';
import IconShare from './icons/IconShare';
import IconHelp from './icons/IconHelp';
import IconInfo from './icons/IconInfo';
import IconSwitch from './icons/IconSwitch';
import IconDelete from './icons/IconDelete';
import IconEdit from './icons/IconEdit';
import IconRun from './icons/IconRun';
import IconRemove from './icons/IconRemove';
import IconSearch from './icons/IconSearch';
import IconSun from './icons/IconSun';
import IconMoon from './icons/IconMoon';
import IconEye from './icons/IconEye';
import IconStop from './icons/IconStop';
import IconList from './icons/IconList';
import IconOpenView from './icons/IconOpenView';
import IconSave from './icons/IconSave';
import IconLink from './icons/IconLink';
import IconPing from './icons/IconPing';
import IconSandbox from './icons/IconSandbox';
import IconPivot from './icons/IconPivot';
import IconDropdown from './icons/IconDropdown';
import IconIntegrations from './icons/IconIntegrations';
import IconHistory from './icons/IconHistory';
import IconShovel from './icons/IconShovel';
import IconClockface from './icons/IconClockface';
import IconClockface2 from './icons/IconClockface2';
import IconToggleOpen from './icons/IconToggleOpen';
import IconToggleClose from './icons/IconToggleClose';
import IconCheckboxEmpty from './icons/Checkbox/IconCheckboxEmpty';
import IconCheckboxChecked from './icons/Checkbox/IconCheckboxChecked';
import IconCheckboxMinusChecked from './icons/Checkbox/IconCheckboxMinusChecked';
import IconCommunityMode from './icons/IconCommunityMode';
import IconUpload from './icons/IconUpload';
import IconGenerateReport from './icons/IconGenerateReport';
import IconQRCode from './icons/IconQRCode';

export const ICON_REGISTRY = {
  logo: IconLogo,
  wordmark: IconWordmark,
  globe: IconGlobe,
  arrow: IconArrow,
  'arrow-right': IconArrowRight,
  'arrow-solid': IconArrowSolid,
  'arrow-full': IconArrowFull,
  drag: IconDrag,
  benign: IconBenign,
  malicious: IconMalicious,
  fail: IconFail,
  question: IconQuestion,
  reload: IconReload,
  check: IconCheck,
  'check-alt': IconCheckAlt,
  close: IconClose,
  copy: IconCopy,
  gear: IconGear,
  power: IconPower,
  profit: IconProfit,
  loss: IconLoss,
  more: IconMore,
  star: IconStar,
  assertions: IconAssertions,
  accuracy: IconAccuracy,
  token: IconToken,
  microengine: IconMicroengine,
  plus: IconPlus,
  'plus-alt': IconPlusAlt,
  warning: IconWarning,
  success: IconSuccess,
  rescan: IconRescan,
  download: IconDownload,
  'download-progress': IconDownloadProgress,
  share: IconShare,
  help: IconHelp,
  info: IconInfo,
  switch: IconSwitch,
  delete: IconDelete,
  edit: IconEdit,
  run: IconRun,
  remove: IconRemove,
  search: IconSearch,
  sun: IconSun,
  moon: IconMoon,
  eye: IconEye,
  stop: IconStop,
  list: IconList,
  save: IconSave,
  'open-view': IconOpenView,
  link: IconLink,
  ping: IconPing,
  sandbox: IconSandbox,
  pivot: IconPivot,
  dropdown: IconDropdown,
  integrations: IconIntegrations,
  history: IconHistory,
  shovel: IconShovel,
  clockface: IconClockface,
  clockface2: IconClockface2,
  'toggle-open': IconToggleOpen,
  'toggle-close': IconToggleClose,
  'checkbox-checked': IconCheckboxChecked,
  'checkbox-empty': IconCheckboxEmpty,
  'checkbox-minus-checked': IconCheckboxMinusChecked,
  community_mode: IconCommunityMode,
  upload: IconUpload,
  'generate-report': IconGenerateReport,
  'qr-code': IconQRCode,
};

const IconBase = () => (
  <svg css={ownStyle} width='0' height='0' version='1.1' xmlns='http://www.w3.org/2000/svg'>
    <defs>
      {Object.entries(ICON_REGISTRY).map(([name, element]) =>
        React.createElement(element, { key: name })
      )}
    </defs>
  </svg>
);

const ownStyle = css`
  position: absolute;
  width: 0;
  height: 0;
`;

export default IconBase;
