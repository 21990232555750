import React, { useState, useEffect, useCallback, useRef } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { makeStyles } from 'views/components/providers/ThemeProvider';
import styles from 'views/styles';
import Button from '@material-ui/core/Button';
import ScanSearchInput, { IScanSearchInput } from './ScanSearchInput';
import FileSelect from 'views/components/Button/FileSelect';
import { ZipPassword } from 'views/components/ZipPassword';
import styled from '@emotion/styled';
import fileUploadImg from 'assets/images/file-upload.svg';
import fileUploadDarkImg from 'assets/images/file-upload-dark.svg';
import { Checkbox } from '@material-ui/core';

type fileUploadMessages = { scan: { [T: string]: string } } & {
  dragover?: { [K: string]: string };
};

export interface IScanFileState {
  hasHeading?: boolean;
  isScan?: boolean;
  onlyHash?: boolean;
  isDragActive?: boolean;
  isInputInitiallyVisible?: boolean;
  selectFileWhenMounted?: boolean;
  onFileSelect: (
    files: File[],
    checkZip?: boolean,
    zipConfig?: { isZip: boolean; zipPassword?: string },
    isQRCode?: boolean
  ) => void;
  onScanSearch: (query: string) => void;
  messages?: fileUploadMessages & { hashText?: IScanSearchInput['messages'] };
  errorMessage?: string;
  file?: File;
  isZipFile?: boolean;
  couldBeQRCode?: boolean;
}

const messages = {
  scan: {
    heading: 'Scan Files or URLs for threats',
    text: 'Drop your file to upload',
  },
  dragover: {
    heading: 'Drop your file',
    text: 'Anywhere on the screen',
  },
};

const Wrapper = styled.div``;

const Heading = styled.h2`
  text-align: center;
  font-weight: 600;
  font-size: ${styles.font.size.h3};
  line-height: 1.2;
  color: #3f3f3f;
  padding-bottom: 1rem;
  font-weight: bold;
`;

const Hint = styled.div`
  font-weight: regular;
`;

const SelectFileButton = styled.span`
  cursor: pointer;
  font-weight: bold;
`;

const StyledFileSelect = styled(FileSelect)`
  margin-left: 2rem;
`;

const ScanFileState = ({
  onlyHash = false,
  isDragActive,
  selectFileWhenMounted,
  onFileSelect,
  onScanSearch,
  messages: messagesProp,
  isScan = false,
  errorMessage,
  hasHeading = true,
  file,
  isZipFile = false,
  couldBeQRCode = false,
}: IScanFileState) => {
  const { theme, classes } = useStyles(isDragActive);
  const [zipConfig, setZipConfig] = useState<{
    isZip: boolean;
    zipPassword?: string;
  }>();
  const [isQRCode, setIsQRCode] = useState<boolean>(false);

  const inputRef = useRef<HTMLLabelElement>(null);
  const _clickFileInput = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }, [inputRef]);

  // Only runs on mount to prevent multiple file select prompts
  useEffect(() => {
    if (selectFileWhenMounted) {
      _clickFileInput();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const msg = { ...messages, ...(messagesProp ?? {}) };
  const title = isDragActive ? 'dragover' : 'scan';

  return (
    <Wrapper>
      {hasHeading && <Heading css={classes.header}>{msg[title].heading}</Heading>}
      <div css={classes.dropZone} data-cy='scanFileState'>
        {file ? (
          <div>{file.name}</div>
        ) : (
          <div onClick={_clickFileInput}>
            <img
              src={theme === 'dark' ? fileUploadDarkImg : fileUploadImg}
              alt='file upload'
              css={classes.dragIcon}
            />
          </div>
        )}
        <StyledFileSelect id='scan-file-state-select' ref={inputRef} onSelection={onFileSelect}>
          {!file && (
            <Hint css={classes.hint}>
              <SelectFileButton css={classes.selectFileButton}>Select file</SelectFileButton>
              <span>&nbsp;</span>
              or drag and drop
            </Hint>
          )}

          <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <ZipPassword isShown={isZipFile} handleChange={setZipConfig} />

            {couldBeQRCode && (
              <div css={classes.container}>
                <Checkbox
                  color='primary'
                  id='isQRCode'
                  checked={isQRCode}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setIsQRCode(e.target.checked)
                  }
                />
                <label css={classes.label} htmlFor='zipWithPassword'>
                  Is QR Code (only QR Codes with URLs are supported)
                </label>
              </div>
            )}

            {(isZipFile || couldBeQRCode) && (
              <Button
                size='large'
                type='button'
                color='primary'
                variant='contained'
                onClick={() => {
                  couldBeQRCode
                    ? onFileSelect([file!], false, undefined, isQRCode)
                    : onFileSelect([file!], false, zipConfig);
                }}
              >
                {isScan ? 'Scan' : 'Confirm'}
              </Button>
            )}
          </div>
        </StyledFileSelect>
      </div>
      {isScan && (
        <>
          <div style={{ fontWeight: 600, textAlign: 'center', marginTop: 18 }}>
            <b>or</b>
          </div>
          <ScanSearchInput
            onFileSelect={(file) => {
              onFileSelect([file!], false, undefined, true);
            }}
            onlyHash={onlyHash}
            messages={msg?.hashText}
            onSubmit={onScanSearch}
          />
        </>
      )}
      {errorMessage ? <div css={classes.error}>{errorMessage}</div> : null}
    </Wrapper>
  );
};

const useStyles = makeStyles((isDragActive?: boolean) => ({
  base: {
    dropZone: {
      alignItems: 'center',
      zIndex: 1,
      textAlign: 'center',
      borderWidth: 3,
      borderStyle: 'dashed',
      borderRadius: styles.border.radius,
      borderColor: styles.color.greyishPurple,
      pointerEvents: isDragActive ? 'none' : 'auto',
      flexDirection: 'row',
      display: 'flex',
      justifyContent: 'center',
      padding: '3rem 0 3rem 0',
    },
    dragIcon: {
      cursor: 'pointer',
      width: '6rem',
      transition: isDragActive ? 'none' : `color ${styles.easing.time} ${styles.easing.main}`,
      color: styles.color.purple,
    },
    selectFileButton: {
      color: styles.color.purple,
    },
    header: {
      color: styles.color.black,
    },
    hint: {
      color: styles.color.lightGrey,
    },
    error: {
      color: styles.color.red,
      textAlign: 'center',
      marginTop: '1rem',
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '-1.2rem',
    },
    label: {
      fontSize: '1.2rem',
    },
  },
  light: {
    dropZone: {
      borderColor: isDragActive ? styles.color.purple : styles.color.greyishPurple,
      backgroundColor: isDragActive ? 'rgba(109, 58, 236, 0.07)' : 'transparent',
    },
    selectFileButton: {
      '&:hover': {
        color: styles.color.xLightGrey,
      },
    },
    dragIcon: {
      '&:hover': {
        color: styles.color.xLightGrey,
      },
    },
    header: {
      color: styles.color.black,
    },
    hint: {
      color: styles.color.lightGrey,
    },
  },
  dark: {
    dropZone: {
      borderColor: isDragActive ? styles.color.xxLightGrey : styles.color.greyishPurple,
      backgroundColor: isDragActive ? 'rgba(172, 172, 187, 0.07)' : 'transparent',
    },
    selectFileButton: {
      color: styles.color.lightBlue,
      '&:hover': {
        color: styles.color.xLightGrey,
      },
    },
    dragIcon: {
      '&:hover': {
        color: styles.color.xLightGrey,
      },
    },
    header: {
      color: styles.color.xxLightGrey,
    },
    hint: {
      color: styles.color.xxxLightGrey,
    },
  },
}));

export default ScanFileState;
