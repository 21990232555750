import {
  AssertionRes,
  SubmissionResultRes,
  ArtifactRes,
  AssertionMetadataRes,
} from 'services/api/schema/submission';
import { RequestError } from 'utils/error';
import { Page } from './Page';
import { CamelCaseType } from 'services/api/schema/utils';

export interface Assertion extends AssertionRes {}

export interface SubmissionResult extends SubmissionResultRes {}

export interface Artifact extends ArtifactRes {}

export type Artifacts = Page<Artifact>;

export interface AssertionMetadata extends AssertionMetadataRes {}

export type ArtifactAttributes = {
  first_seen: string;
  last_seen: string | null;
  last_scanned: string | null;
  mimetype: string;
  extended_type: string;
  md5: string;
  sha1: string;
  sha256: string;
};

// NOTE: these may not be the only tools available.
export type ToolName =
  | 'lief'
  | 'strings'
  | 'pefile'
  | 'polyunite'
  | 'hash'
  | 'exiftool'
  | 'cape_sandbox_v2'
  | 'jarm_v0'
  | 'triage_sandbox_v0';

export type ToolMetadata = {
  [key: string]: string | number | boolean | (string | number)[] | ToolMetadata;
};

export type ArtifactMetadata = {
  [toolName in ToolName]?: ToolMetadata | null;
};

export type ArtifactDetections = {
  malicious: number;
  total: number;
};

export type LatestScan = {
  artifact_instance_id: string;
};

export interface NormalizedArtifactMetadata {
  attributes: ArtifactAttributes & { type: string; names: string[] };
  metadata: ArtifactMetadata;
  latest_scan: LatestScan;
  id?: string;
  sha256: string;
  submitted: string;
  last_seen: string;
  file_size: string;
  file_type: string;
  detections: ArtifactDetections;
  polyscore: number | null;
  polyunite_malware_family: string | null;
  malware_family?: string | null;
  latest_scan_polyscore: number | null;
}

export interface ManualAnalysis {
  tier: 'tier-3' | 'tier-4';
  instanceId: string;
  responseTime: string;
  format: 'report' | 'short-blurb' | 'presentation' | 'verbal-form';
  audience: string;
  purpose: string;
  description: string;
}

export type NormalizedSearchResult = {
  attributes: ArtifactAttributes & { names: string[]; type: string };
  metadata: ArtifactMetadata;
  artifact_id: string;
  hash: string;
  sha256: string;
  polyscore: number | null;
  submitted: string;
  detections: ArtifactDetections;
  network?: string;
  error?: RequestError;
};

export type PaginatedNormalizedSearchResult = { [page: number]: NormalizedSearchResult[] };

export type PaginatedArtifactInstances = {
  resultsAvailable?: number;
  hasMore: boolean;
  offset: string;
  limit: number;
  status: string;
  result: CamelCaseType<Artifact>[];
};

export type HashType = 'md5' | 'sha1' | 'sha256';

export enum ESubmissionResultType {
  FILE = 'FILE',
  URL = 'URL',
}

export enum EScanStatus {
  PENDING = 'PENDING',
  RUNNING = 'RUNNING',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
}
