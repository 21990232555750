import React from 'react'; // eslint-disable-line
import { jsx, css } from '@emotion/core'; /** @jsx jsx */ /** @jsxRuntime classic */
import styles from 'views/styles';
import { ButtonProps } from '@material-ui/core/Button';
import Modal from 'views/components/Modal';
import FormButtonBar from 'views/components/form/CustomForm/FormButtonBar';
import Loader from 'views/components/Loader';
import { DialogProps } from '@material-ui/core/Dialog';

interface Props {
  className?: string;
  isLoading: boolean;
  errorMessage?: string;
  heading?: string;
  text?: string;
  content?: React.ReactNode;
  submitBtnText: string;
  buttonsDirection?: 'horizontal' | 'vertical';
  submitBtnColor?: ButtonProps['color'];
  submitBtnVariant?: ButtonProps['variant'];
  cancelBtnText?: string;
  cancelBtnColor?: ButtonProps['color'];
  cancelBtnVariant?: ButtonProps['variant'];
  onSubmit: (value?: any) => void;
  onCancel?: () => void;
  testId?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  maxContentWidth?: string;
  maxWidth?: DialogProps['maxWidth'];
  timeout?: number;
  onTimeout?: () => void;
}

const ConfirmModal = ({
  className,
  isLoading,
  errorMessage,
  heading,
  text,
  content,
  submitBtnText,
  buttonsDirection,
  submitBtnColor,
  submitBtnVariant,
  cancelBtnText,
  cancelBtnColor,
  cancelBtnVariant,
  onSubmit,
  onCancel,
  testId,
  disabled,
  fullWidth,
  maxContentWidth,
  maxWidth,
  timeout,
  onTimeout,
}: Props) => (
  <Modal fullWidth={fullWidth} maxWidth={maxWidth}>
    <div className={className} css={style.root} data-testid={testId}>
      <div css={style.content(isLoading, maxContentWidth)}>
        {heading && <h3 className='h3 h-mb-tiny'>{heading}</h3>}
        {(text || '').split('\n').map((p: string, index) => (
          <p className='p' key={`c-p-${index}`}>
            {p}
          </p>
        ))}
        {errorMessage && (
          <p className='p1 h-mt-xs' css={style.error} data-cy='confirmModalError'>
            {errorMessage}
          </p>
        )}
        {content && <div>{content}</div>}
        <FormButtonBar
          className='h-mt-xs'
          submitBtnText={submitBtnText}
          direction={buttonsDirection}
          submitBtnColor={submitBtnColor}
          submitBtnVariant={submitBtnVariant}
          cancelBtnText={cancelBtnText}
          cancelBtnColor={cancelBtnColor}
          cancelBtnVariant={cancelBtnVariant}
          onSubmit={onSubmit}
          onCancel={onCancel}
          disabled={disabled}
          timeout={timeout}
          onTimeout={onTimeout}
        />
      </div>
      {isLoading && <Loader css={style.loader} testId='confirmModalLoader' />}
    </div>
  </Modal>
);

const style = {
  root: css`
    position: relative;
  `,
  content: (isLoading: boolean, maxContentWidth?: string) => css`
    visibility: ${isLoading ? 'hidden' : 'visible'};
    text-align: center;
    padding: ${styles.spacing.md};
    max-width: ${maxContentWidth ? maxContentWidth : '53rem'};
    margin: 0 auto;
  `,
  loader: css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.6rem;
  `,
  error: css`
    color: ${styles.color.red};
  `,
};

export default ConfirmModal;
