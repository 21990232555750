import React from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { NavLink } from 'react-router-dom';
import { FormattedMessage, defineMessages } from 'react-intl';
import { CONSUMER_DOCS_URL } from 'utils/constants';
import styles from 'views/styles';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { useIsPageEnabled } from 'hooks/useIsPageEnabled';

const messages = defineMessages({
  company: {
    id: 'footer.nav.company',
    defaultMessage: 'Company',
  },
  howItWorks: {
    id: 'footer.nav.howItWorks',
    defaultMessage: 'How It Works',
  },
  docs: {
    id: 'footer.nav.docs',
    defaultMessage: 'Docs',
  },
  privacy: {
    id: 'footer.nav.privacy',
    defaultMessage: 'Privacy Policy',
  },
  terms: {
    id: 'footer.nav.terms',
    defaultMessage: 'Terms of Service',
  },
  engineAgreement: {
    id: 'footer.nav.engineAgreement',
    defaultMessage: 'Engine Agreement',
  },
  accessibility: {
    id: 'footer.nav.accessibility',
    defaultMessage: 'Accessibility',
  },
  status: {
    id: 'footer.nav.status',
    defaultMessage: 'Status',
  },
});

const FooterNav = () => {
  const { classes } = useStyles();
  const page = useIsPageEnabled();

  const items = [
    {
      id: 1,
      href: 'https://polyswarm.io/',
      label: messages.company,
      show: page.isEnabled('company'),
    },
    {
      id: 2,
      to: '/how-it-works',
      label: messages.howItWorks,
      show: page.isEnabled('how-it-works'),
    },
    {
      id: 3,
      href: CONSUMER_DOCS_URL,
      label: messages.docs,
      show: page.isEnabled('docs'),
    },
    {
      id: 4,
      to: '/privacy',
      label: messages.privacy,
      show: page.isEnabled('privacy'),
    },
    {
      id: 5,
      to: '/terms',
      label: messages.terms,
      show: page.isEnabled('terms'),
    },
    {
      id: 6,
      to: '/engine-agreement',
      label: messages.engineAgreement,
      show: page.isEnabled('engine-agreement'),
    },
    {
      id: 7,
      to: '/accessibility',
      label: messages.accessibility,
      show: page.isEnabled('accessibility'),
    },
    {
      id: 8,
      href: 'https://status.polyswarm.network/',
      label: messages.status,
      show: page.isEnabled('status'),
    },
  ];

  return (
    <ul css={classes.root}>
      {items
        .filter((item) => item.show)
        .map((item) => (
          <li key={item.id}>
            {item.to ? (
              <NavLink to={item.to} activeClassName='is-active'>
                <FormattedMessage {...item.label} />
              </NavLink>
            ) : (
              <a href={item.href} target='_blank' rel='noopener noreferrer'>
                <FormattedMessage {...item.label} />
              </a>
            )}
          </li>
        ))}
    </ul>
  );
};

const useStyles = makeStyles({
  base: {
    root: {
      marginLeft: styles.spacing.xxs,
      li: {
        display: 'inline',
        marginLeft: styles.spacing.xs,
        a: {
          fontSize: styles.font.size.p1,
          textDecoration: 'none',
          transition: `color ${styles.easing.time} ${styles.easing.main}`,
        },
      },
    },
  },
  light: {
    root: {
      li: {
        a: {
          color: styles.color.lightGrey,
          '&:hover': {
            color: styles.color.purple,
          },
          '&:active, &.is-active': {
            color: styles.color.medPurple,
          },
        },
      },
    },
  },
  dark: {
    root: {
      li: {
        a: {
          color: styles.color.xLightGrey,
          '&:hover': {
            color: styles.color.lightBlue,
          },
          '&:active, &.is-active': {
            color: styles.color.xLightBlue,
          },
        },
      },
    },
  },
});

export default FooterNav;
