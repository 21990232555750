import axios, { AxiosInstance, AxiosPromise, AxiosRequestConfig, CancelToken, Method } from 'axios';
import qs from 'query-string';
import config from 'config';
import { ENotificationInterval, NotificationIntervalKey } from 'models/Account';
import { User } from 'models/User';
import { PageQuery } from 'models/Page';
import { store } from 'state/store';
import {
  ContextAccount,
  contextAccount,
  isAuthenticated,
  parseContextAccount,
} from 'state/auth/selectors';
import {
  IInviteTeamMemberValues,
  IUpdateTeamMemberValues,
  ITeamValues,
  TeamMember,
  TeamMemberInvitation,
} from 'models/Team';
import { SandboxProviders } from 'models/Sandbox';
import { InvitationResponseAction } from 'models/Invitation';
import { Expert } from 'models/Community';
import { Ruleset, RulesetValues, Hunt, ScanType } from 'models/Ruleset';
import {Artifact, HashType, ManualAnalysis, PaginatedArtifactInstances} from 'models/Submission';
import { IClaimUserMicroengineValues, MicroengineValues } from 'models/Microengine';
import { TimeSeriesQuery, PageTimeQuery, TimeSeriesAttributes } from 'models/TimeSeries';
import { IAccountSignupValues } from 'models/Account';
import { ProviderRes } from 'models/Sandboxing';
import { getHashType } from 'utils/isHash';
import { UserRes, UserStatsRes } from './schema/user';
import { SystemStatusRes } from './schema/system';
import { ApiKeyForm } from 'models/ApiKey';
import { ApiKeysRes, ApiKeyRes, ApiKeyDataRes } from './schema/apiKey';
import { Integration } from 'models/Integration';
import { IntegrationRes } from './schema/integration';
import { WebhooksRes, WebhookRes, WebhookDataRes, WebhookAttemptRes } from './schema/webhooks';
import {
  FeeRes,
  TelemetrySummaryRes,
  WalletEnrollmentRes,
  WalletRes,
  WalletTransactionRes,
} from './schema/wallets';
import { AnalyticsRes, HistoricalStatsRes, AnalyticsData } from './schema/analytics';
import {
  AccountPlansRes,
  AccountRes,
  AccountContextRes,
  NotificationSettingsRes,
  NotificationSettingRes,
  PricingFormSubmitRes,
  PricingFormVerifyEmailRes,
  PaymentMethodsRes,
  SubscriptionTransactionsRes,
  PaymentMethodRes,
  PaymentCouponRes,
} from './schema/account';
import {
  FileSizeLimitRes,
  SubmissionResultRes,
  SubmissionV2Res,
  MetadataSearchParsedRes,
  MetadataSearchResultsRes,
  FileDownloadRes,
  IOCHashResultRes,
  IOCSearchResultRes,
} from './schema/submission';
import {
  TeamRes,
  TeamMemberRes,
  TeamMembersRes,
  TeamMemberInvitationsRes,
  TeamInvitationRes,
  TeamMemberInvitationRes,
} from './schema/team';
import { CommunitiesRes, CommunityRes, ExpertsRes } from './schema/communities';
import {
  MicroengineRes,
  MicroengineTagsRes,
  MicroengineArtifactTypesRes,
  MicroengineMimeTypesRes,
  AllMicroenginesRes,
  UserMicroenginesRes,
  MicroengineListingRes,
  MicroengineClaimRes,
  PublicMicroengineListingRes,
  DevelopmentCommunityRes,
  DevelopmentResultRes,
} from './schema/microengines';
import {
  RulesetsRes,
  RulesetRes,
  HuntRes,
  HuntsRes,
  RulesetDownloadRes,
  RulesetValidationRes,
  LiveHuntRes,
  LiveHuntsRes,
} from './schema/hunting';
import { InvitationRes } from './schema/invitations';
import { ThreatsRes } from './schema/tags';
import { logout } from 'state/auth/actions';
import {
  ReportInput,
  ReportRes,
  PaginatedTemplateRes,
  TemplateInput,
  TemplateRes,
} from './schema/report';
import { TeamActivity } from 'views/pages/TeamDetailPage/TeamDetailPanel/TeamDetailTabs/TeamTeamActivityTab/TeamActivityTable/types';
import { fileToBinary } from 'utils/files';

class ApiService {
  private request = axios.create({
    baseURL: config.apiUrl,
    withCredentials: true,
  });
  private externalRequest = axios.create();

  // left public for mocking
  _isAuthenticated = () => {
    const state = store.getState().auth;
    return isAuthenticated(state);
  };

  // left public for mocking
  _getAccessToken = () => {
    const { tokens } = store.getState().auth;
    return tokens && tokens.accessToken;
  };

  /** Safely rename all 'result' keys to 'results' if the former is present. */
  private canonicalizeResultPath = (obj: any) => {
    const oldKey = 'result';
    const newKey = 'results';
    for (const target of ['data' in obj && obj.data, obj]) {
      if (target && Array.isArray(target[oldKey])) {
        delete Object.assign(target, { [newKey]: target[oldKey] })[oldKey];
        break;
      }
    }

    return obj;
  };

  /** extract results from a response */
  private extractResults = (obj: any) => {
    obj.data = obj.data.results || obj.data.result;
    return obj;
  };

  constructor() {
    this.request.interceptors.request.use((config) => {
      if (this._isAuthenticated()) {
        const ctx = contextAccount(store);
        if (ctx && !config.headers['X-Context-Account']) {
          // when user is logged in context should always be !=null, but just in case
          config.headers['X-Context-Account'] = parseContextAccount(ctx);
        }
        config.headers.Authorization = `Bearer ${this._getAccessToken()}`;
      }
      return config;
    });

    this.request.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 401) {
          const hostname = new URL(error.request.responseURL).hostname;
          if (hostname.startsWith('login.') || hostname.includes('auth0')) {
            // @ts-ignore
            store.dispatch(logout());
            // store.dispatch({ type: AuthActionName.ACCESS_DENIED_ERROR });
          }
        }
        return Promise.reject(error);
      }
    );

    this.request.defaults.headers.common['X-Polyswarm-Deploy-Id'] = config.deployId;
  }

  /**
   * Access the request object for mocking.
   */
  client() {
    return this.request;
  }

  // ---------  Auth  --------- //

  login(accessToken: string): AxiosPromise<UserRes> {
    return this.request.post('/v1/login', null, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  signUp(accessToken: string, profile: IAccountSignupValues): AxiosPromise<UserRes> {
    return this.request.post('/v1/signup', profile, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  checkUsername(username: string): AxiosPromise<boolean> {
    return this.request.post('/v1/username', { username });
  }

  sendVerificationEmail(accessToken: string): AxiosPromise<{}> {
    return this.request.post(
      '/v1/signup/verification-email',
      {},
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
  }

  // ---------  System  --------- //

  getSystemStatus(): AxiosPromise<SystemStatusRes> {
    return this.request.get('/v1/system/status');
  }

  // ---------  User  --------- //

  getSelf(): AxiosPromise<UserRes> {
    return this.request.get('/v1/users/self');
  }

  updateUser(userId: number, data: Partial<User>): AxiosPromise<UserRes> {
    return this.request.patch(`/v1/users/${userId}`, data);
  }

  enableUserMfa(): AxiosPromise<UserRes> {
    return this.request.post('/v1/users/mfa');
  }

  disableUserMfa(): AxiosPromise<UserRes> {
    return this.request.delete('/v1/users/mfa');
  }

  getUserStats(userId: number, params?: TimeSeriesAttributes): AxiosPromise<UserStatsRes> {
    return this.request.get(`/v1/users/${userId}/stats`, { params });
  }

  archiveUser(userId: number): AxiosPromise<{}> {
    return this.request.post(`/v1/users/${userId}/archive`);
  }

  // ---------  API Keys  --------- //

  getApiKeys(userId: number, params?: PageQuery<ApiKeyDataRes>): AxiosPromise<ApiKeysRes> {
    return this.request.get(`/v1/users/${userId}/api-keys`, { params });
  }

  createApiKey(userId: number, apiKeyData: Partial<ApiKeyForm>): AxiosPromise<ApiKeyRes> {
    return this.request.post(`/v1/users/${userId}/api-keys`, apiKeyData);
  }

  archiveApiKey(userId: number, id: string): AxiosPromise<ApiKeyRes> {
    return this.request.post(`/v1/users/${userId}/api-keys/${id}/archive`);
  }

  // ---------  Webhooks  --------- //

  getWebhook(accountNumber: number, webhookId: string): AxiosPromise<WebhookRes> {
    return this.request.get(`/v1/accounts/${accountNumber}/webhook/${webhookId}`);
  }

  getWebhooks(
    accountNumber: number,
    params?: PageQuery<WebhookDataRes>
  ): AxiosPromise<WebhooksRes> {
    return this.request.get(`/v1/accounts/${accountNumber}/webhooks`, { params });
  }

  createWebhook(accountNumber: number, values: any): AxiosPromise<WebhookRes> {
    return this.request.post(`/v1/accounts/${accountNumber}/webhooks`, values);
  }

  testWebhook(accountNumber: number, webhookId: string): AxiosPromise<WebhookAttemptRes> {
    return this.request.post(`/v1/accounts/${accountNumber}/webhooks/${webhookId}/test`);
  }

  archiveWebhook(userId: number, webhookId: string): AxiosPromise<WebhookRes> {
    return this.request.post(`/v1/accounts/${userId}/webhooks/${webhookId}/archive`);
  }

  // ---------  Analytics  --------- //

  getLatestAnalyticsData(): AxiosPromise<AnalyticsData<AnalyticsRes>> {
    return this.request.get(`/v1/analytics/latest`);
  }

  getHistoricalAnalyticsData(): AxiosPromise<AnalyticsData<HistoricalStatsRes[]>> {
    return this.request.get(`/v1/analytics/historical`);
  }

  // ---------  Account  --------- //

  submitPricingForm(values: any): AxiosPromise<PricingFormSubmitRes> {
    const options = values.options?.map((option: any) =>
      typeof option === 'object' ? option.value : option
    );
    return this.request.post('/v1/pricing/submit', { ...values, options });
  }

  verifyPricingFormEmail(token: string): AxiosPromise<PricingFormVerifyEmailRes> {
    return this.request.post('/v1/pricing/verify-email', { token });
  }

  getAccountPlans(): AxiosPromise<AccountPlansRes> {
    return this.request.get('/v1/accounts/plans');
  }

  getAccount(accountNumber: number | string): AxiosPromise<AccountRes> {
    return this.request.get(`/v1/accounts/${accountNumber}`);
  }

  switchAccount(accountNumber: number | string): AxiosPromise<AccountContextRes> {
    return this.request.post(`/v1/accounts/${accountNumber}/switch`);
  }

  getAccountContext(ctx?: ContextAccount): AxiosPromise<AccountContextRes> {
    const config: AxiosRequestConfig = {};
    if (ctx) {
      config.headers = {
        'X-Context-Account': parseContextAccount(ctx),
      };
    }
    return this.request.get('/v1/accounts/context', config);
  }

  enableNotifications(accountNumber: number): AxiosPromise<{}> {
    return this.request.post(`/v1/accounts/${accountNumber}/notifications/toggle`, {
      enabled: true,
    });
  }

  getNotificationSettings(accountNumber: number): AxiosPromise<NotificationSettingsRes> {
    return this.request.get(`/v1/accounts/${accountNumber}/notifications`);
  }

  updateNotificationSetting(
    accountNumber: number,
    notificationId: number,
    isEnabled: boolean,
    interval?: NotificationIntervalKey
  ): AxiosPromise<NotificationSettingRes> {
    return this.request.post(`/v1/accounts/${accountNumber}/notifications/${notificationId}`, {
      is_notify: isEnabled,
      interval: interval ? ENotificationInterval[interval] : ENotificationInterval['24h'],
    });
  }

  createSubscription(
    accountNumber: number,
    paymentMethod: string,
    plan: string,
    customerId?: string | null,
    coupon?: string | null
  ): AxiosPromise<{ id: string }> {
    return this.request.post(`/v1/accounts/${accountNumber}/subscription`, {
      paymentMethod,
      plan,
      customerId,
      coupon,
    });
  }

  createCustomer(
    accountNumber: number,
    paymentMethod: string,
    country: string,
    postalCode: string
  ): AxiosPromise<{ id: string }> {
    return this.request.post(`/v1/accounts/${accountNumber}/payment-customer`, {
      paymentMethod,
      country,
      postal_code: postalCode,
    });
  }

  attachPaymentMethod(
    accountNumber: number,
    paymentMethod: string,
    customerId?: string | null
  ): Promise<PaymentMethodRes> {
    return this.request
      .post(`/v1/accounts/${accountNumber}/payment-method`, {
        customer: customerId,
        paymentMethod,
      })
      .then((res) => res.data)
      .catch((e) => {
        throw new Error(e.response.data?.error?.response?.data || e.message);
      });
  }

  detachPaymentMethod(accountNumber: number, paymentMethod: string): Promise<PaymentMethodRes> {
    return this.request
      .delete(`/v1/accounts/${accountNumber}/payment-method/${paymentMethod}`)
      .then((res) => res.data)
      .catch((e) => {
        throw new Error(e.response.data?.error?.response?.data || e.message);
      });
  }

  setDefaultPaymentMethod(accountNumber: number, paymentMethod: string): Promise<boolean> {
    return this.request
      .post(`/v1/accounts/${accountNumber}/payment-method/default`, {
        paymentMethod,
      })
      .then((res) => res.data)
      .catch((e) => {
        throw new Error(e.response.data?.error?.response?.data || e.message);
      });
  }

  updateSubscription(
    accountNumber: number,
    plan: string,
    paymentMethod: string
  ): AxiosPromise<{ message: string }> {
    return this.request.patch(`/v1/accounts/${accountNumber}/subscription`, {
      plan,
      paymentMethod,
    });
  }

  cancelPendingSubscription(accountNumber: number): AxiosPromise<{ message: string }> {
    return this.request.delete(`/v1/accounts/${accountNumber}/subscription/pending`);
  }

  getPaymentMethods(
    accountNumber: number,
    customerId?: string | null
  ): AxiosPromise<PaymentMethodsRes> {
    if (customerId) {
      return this.request.get(`/v1/accounts/${accountNumber}/payment-methods/${customerId}`);
    }

    return this.request.get(`/v1/accounts/${accountNumber}/payment-methods`);
  }

  getSubscriptionTransactions(accountNumber: number): AxiosPromise<SubscriptionTransactionsRes> {
    return this.request.get(`/v1/accounts/${accountNumber}/subscription-transactions`);
  }

  getPaymentDashboardLink(accountNumber: number): AxiosPromise<string> {
    return this.request.get(`/v1/accounts/${accountNumber}/payment-dashboard-link`);
  }

  getCoupon(couponId: string): AxiosPromise<PaymentCouponRes> {
    return this.request.get(`/v1/pricing/coupon/${couponId}`);
  }

  setFeatureOverage(accountNumber: number, featureTag: string, overage: number): AxiosPromise<{}> {
    return this.request.put(`/v1/accounts/${accountNumber}/feature/${featureTag}/overage`, {
      value: overage,
    });
  }

  getTeamActivity(params: Record<string, unknown>): AxiosPromise<TeamActivity> {
    return this.request.get(`/v2/activity`, { params });
  }

  // ---------  Sandbox  --------- //
  getLatestSandboxByHash(hash: string, sandbox: SandboxProviders): AxiosPromise<{}> {
    return this.request.get(`/v2/sandbox/tasks/latest`, {
      params: {
        sha256: hash,
        sandbox: sandbox,
      },
    });
  }

  getSandboxTaskByHashAndId(sha256: string, taskId: string): AxiosPromise<any> {
    return this.request.get(`/v2/sandbox/${sha256}/${taskId}`);
  }

  // ---------  Submission  --------- //

  getFileSizeLimit(): AxiosPromise<FileSizeLimitRes> {
    return this.request.get('/v1/submission/file-size-limit');
  }

  /**
   * 3 steps upload:
   *   1. (this) create the file but without uploading it, and return the URL where
   *      to upload the content and the artifactId to identify the scan.
   *   2. uploadFile method, with the URL returned in 1.
   *   3. confirmV2File method.
   */
  createV2File(
    filename: string,
    zip?: { isZip: boolean; zipPassword?: string },
    isQRCode?: boolean,
    skipScan?: boolean,
  ): AxiosPromise<SubmissionV2Res> {
    const data: any = { filename };
    if (zip?.isZip) {
      data.preprocessing = {
        type: 'zip',
        password: zip.zipPassword,
      };
    } else if (isQRCode) {
      data.artifact_type = 'URL';
      data.preprocessing = {
        type: 'qrcode',
      };
    }
    if (skipScan) {
      data.actions = { _default: false };
    }
    return this.request.post('/v2/submission', data);
  }

  /**
   * 3 steps upload: step 3 (see createV2File() doc).
   */
  confirmV2File(artifactId: string): AxiosPromise<SubmissionResultRes> {
    return this.request.put('/v2/submission', { artifactId });
  }

  uploadFile(
    method: Method,
    url: string,
    file: File,
    reportUploadProgress?: (percentage: number) => void,
    cancelToken?: CancelToken
  ): AxiosPromise<SubmissionResultRes> {
    let request: AxiosInstance;
    let data: any;
    if (url.startsWith('http')) {
      request = this.externalRequest;
      data = file;
    } else {
      request = this.request;
      data = new FormData(); // Content-Type: multipart/form-data
      data.append('file', file, file.name);
    }

    return request.request({
      url,
      method,
      data,
      cancelToken,
      onUploadProgress: reportUploadProgress
        ? (progressEvent) => {
            const percentage =
              progressEvent.loaded && progressEvent.total
                ? Math.round((Number(progressEvent.loaded) * 100) / Number(progressEvent.total))
                : 0;
            reportUploadProgress(percentage);
          }
        : undefined,
    });
  }

  postUrl(url: string, skipScan?: boolean): AxiosPromise<SubmissionResultRes> {
    const data: any = {
      'artifact-type': 'url',
      url,
    };
    if (skipScan) {
      data.actions = { _default: false };
    }
    return this.request.post('/v2/url-submission', data);
  }

  getSubmission(id: string): AxiosPromise<SubmissionResultRes> {
    return this.request.get(`/v1/submission/id/${id}`);
  }

  getSubmissionByHashAndId(sha256: string, id: string): AxiosPromise<SubmissionResultRes> {
    return this.request.get(`/v2/submission/${sha256}/${id}`);
  }

  getSubmissionByUUID(uuid: string): AxiosPromise<SubmissionResultRes> {
    return this.request.get(`/v1/submission/uuid/${uuid}`);
  }

  getSubmissionByHash(hash: string, hashFn?: HashType): AxiosPromise<SubmissionResultRes> {
    if (!hashFn) {
      hashFn = getHashType(hash);
    }

    return this.request.get(`/v1/submission/hash/${hashFn}/${hash}`);
  }

  getUrlSubmissionByHash(hash: string): AxiosPromise<SubmissionResultRes> {
    return this.request.get(`/v1/submission/url/${hash}`);
  }

  getMetadataByHash(hash: string, hashFn?: HashType): AxiosPromise<SubmissionResultRes> {
    if (!hashFn) {
      hashFn = getHashType(hash);
    }

    return this.request.get(`/v1/submission/metadata/hash/${hashFn}/${hash}`);
  }

  getMetadataSearchResults(
    term: string,
    params?: PageQuery<Artifact>
  ): AxiosPromise<MetadataSearchResultsRes> {
    return this.request
      .get('/v1/submission/metadata/search', {
        params: {
          term,
          ...params,
          with_metadata: true,
          with_instances: true,
          with_bounty_results: true,
        },
      })
      .then(this.canonicalizeResultPath)
      .catch((e) => {
        if (e.message === 'Network Error') {
          return Promise.reject({
            message: 'request_timeout',
          });
        } else {
          throw e;
        }
      });
  }

  getIOCSearchByHash(hash: string): AxiosPromise<IOCHashResultRes> {
    return this.request.get(`/v1/ioc/sha256/${hash}`);
  }

  getIOCSeach(params: Record<string, string>): AxiosPromise<IOCSearchResultRes> {
    return this.request.get(`/v1/ioc/search`, { params });
  }

  rescanFile(id: string): AxiosPromise<SubmissionResultRes> {
    return this.request.post(`/v1/submission/rescan/${id}`);
  }

  downloadArtifact(hash: string): AxiosPromise<FileDownloadRes> {
    return this.request.get(`/v1/submission/download/${hash}`);
  }

  downloadSandboxArtifact(hash: string, query?: string): AxiosPromise<FileDownloadRes> {
    return this.request.get(`/v2/instance/download/${hash}${!!query ? `?${query}` : ''}`);
  }

  sandboxArtifact(id: string) {
    return this.request.post(`/v1/submission/sandbox/${id}`);
  }

  parseMetadataSearch(data: any): AxiosPromise<MetadataSearchParsedRes> {
    return this.request.post('/v1/metadata/parse', data);
  }

  scanHistory(hash: string, offset?: string): AxiosPromise<PaginatedArtifactInstances> {
    const params = {
      hash,
      ...(offset ? { offset } : {}),
    };
    return this.request.get(`/v1/submission/instances?${qs.stringify(params)}`);
  }

  allSandboxing(filters: Record<string, string>) {
    return this.request.get(`/v2/sandbox/tasks?${qs.stringify(filters)}`);
  }

  mySandboxing(filters: Record<string, string>) {
    return this.request.get(`/v2/sandbox/my-tasks?${qs.stringify(filters)}`);
  }

  getProviderVMs(): AxiosPromise<ProviderRes> {
    return this.request.get(`/v2/sandbox/providers`);
  }

  submitHashToSandBox(
    hash: string,
    providerVM: {
      providerSlugs: string[];
      vmSlug: string;
      networkEnabled: boolean;
    }
  ) {
    return this.request.post(`/v2/sandbox/submission/sha256/${hash}`, providerVM);
  }

  submitInstanceToSandbox(
    instanceId: string,
    providerVM: {
      providerSlugs: string[];
      vmSlug: string;
      networkEnabled: boolean;
    }
  ) {
    return this.request.post(`/v2/sandbox/submission/${instanceId}`, providerVM);
  }

  submitFileForSandBox(
    filename: string,
    providerVM: {
      providerSlug: string;
      vmSlug: string;
    },
    artifactType: 'URL' | 'FILE',
    zip?: { isZip: boolean; zipPassword?: string },
    isQRCode?: boolean
  ) {
    const data: any = {
      filename,
      ...providerVM,
      artifactType,
    };
    if (zip?.isZip) {
      data.preprocessing = {
        type: 'zip',
        password: zip.zipPassword,
      };
    } else if (isQRCode) {
      data.artifactType = 'URL';
      data.preprocessing = {
        type: 'qrcode',
      };
    }
    return this.request.post(`/v2/sandbox/submission`, data);
  }

  confirmSandboxFile(id: string) {
    return this.request.put('/v2/sandbox/submission', { id });
  }

  manualAnalysis(data: ManualAnalysis) {
    return this.request.post('/v2/submission/manual-analysis', data, { timeout: 16000 });
  }

  // ---------  Teams  --------- //

  createTeam(userId: number, teamValues: ITeamValues): AxiosPromise<TeamRes> {
    return this.request.post(`/v1/users/${userId}/teams`, teamValues);
  }

  getTeamByAccount(accountNumber: number | string): AxiosPromise<TeamRes> {
    return this.request.get(`/v2/teams/${accountNumber}`);
  }

  updateTeam(teamAccountNumber: number | string, teamValues: ITeamValues): AxiosPromise<TeamRes> {
    return this.request.patch(`/v2/teams/${teamAccountNumber}`, teamValues);
  }

  archiveTeam(teamAccountNumber: number | string): AxiosPromise<TeamRes> {
    return this.request.post(`/v2/teams/${teamAccountNumber}/archive`);
  }

  getTeamMembersByAccount(params?: PageQuery<TeamMember>): AxiosPromise<TeamMembersRes> {
    return this.request.get('/v2/teams/members', { params });
  }

  inviteTeamMember(
    memberValues: IInviteTeamMemberValues,
    force = false
  ): AxiosPromise<TeamMemberInvitationsRes> {
    return this.request.post(`/v2/teams/invitations?force=${force ? true : ''}`, memberValues);
  }

  updateTeamMember(
    accountNumber: number,
    memberValues: IUpdateTeamMemberValues
  ): AxiosPromise<TeamMemberRes> {
    return this.request.patch(`/v2/teams/members/${accountNumber}`, memberValues);
  }

  setFeature(data: Record<string, boolean>): AxiosPromise<any> {
    return this.request.put(`/v2/teams/feature`, data);
  }

  archiveTeamMember(accountNumber: number): AxiosPromise<TeamMemberRes> {
    return this.request.post(`/v2/teams/members/${accountNumber}/archive`);
  }

  getTeamInvitations(
    params?: PageQuery<TeamMemberInvitation>
  ): AxiosPromise<TeamMemberInvitationsRes> {
    return this.request.get('/v2/teams/invitations', { params });
  }

  archiveTeamInvitation(invitationId: number): AxiosPromise<TeamMemberInvitationRes> {
    return this.request.post(`/v2/teams/invitations/${invitationId}/archive`);
  }

  resendTeamInvitation(invitationId: number): AxiosPromise<TeamMemberInvitationsRes> {
    return this.request.put(`/v2/teams/invitations/${invitationId}/refresh`);
  }

  forceTeamMfa(teamAccountNumber: number | string, force: boolean): AxiosPromise<TeamRes> {
    return this.request.patch(`/v2/teams/${teamAccountNumber}/forceMfa`, { forceMfa: force });
  }

  requestFeature(accountNumber: number, feature: string) {
    return this.request.post(`/v1/accounts/${accountNumber}/feature/request`, {
      features: [feature],
    });
  }

  reportFalsePositive(data: { sha256: string; instanceId: string; description: string }) {
    return this.request.post(`/v1/submission/report-fp`, data);
  }

  setTeamTheme(accountNumber: number, theme: string) {
    return this.request.put(`/v2/teams/${accountNumber}/theme`, { theme });
  }

  // ---------  Invitations  --------- //

  getInvitation(token: string): AxiosPromise<TeamInvitationRes> {
    return this.request.get(`/v1/invitations/${token}`);
  }

  handleInvitation(token: string, action: InvitationResponseAction): AxiosPromise<InvitationRes> {
    return this.request.post(`/v1/invitations/${token}/${action}`);
  }

  // ---------  Communities  --------- //

  getAllCommunities(params?: TimeSeriesQuery): AxiosPromise<CommunitiesRes> {
    return this.request.get('/v1/communities', { params });
  }

  getCommunity(communityId: string): AxiosPromise<CommunityRes> {
    return this.request.get(`/v1/communities/${communityId}`);
  }

  getExperts(communityId: string, params?: PageTimeQuery<Expert>): AxiosPromise<ExpertsRes> {
    return this.request.get(`/v1/communities/${communityId}/experts`, { params });
  }

  // ---------  Microengines  --------- //

  getAllMicroengines(
    params?: PageTimeQuery<PublicMicroengineListingRes>
  ): AxiosPromise<AllMicroenginesRes> {
    return this.request.get('/v1/microengines', { params });
  }

  getMicroengine(engineId: string): AxiosPromise<MicroengineListingRes> {
    return this.request.get(`/v1/microengines/${engineId}`);
  }

  getMicroengineTags(): AxiosPromise<MicroengineTagsRes> {
    return this.request.get(`/v1/microengines/tags`);
  }

  getMicroengineArtifactTypes(): AxiosPromise<MicroengineArtifactTypesRes> {
    return this.request.get(`/v1/microengines/artifacttypes`);
  }

  getMicroengineMimeTypes(): AxiosPromise<MicroengineMimeTypesRes> {
    return this.request.get(`/v1/microengines/mimetypes`);
  }

  getUserMicroengines(
    accountNumber: number,
    params?: PageTimeQuery<MicroengineListingRes>
  ): AxiosPromise<UserMicroenginesRes> {
    return this.request.get(`/v1/accounts/${accountNumber}/microengines`, { params });
  }

  createUserMicroengine(
    accountNumber: number,
    microengineValues: MicroengineValues
  ): AxiosPromise<MicroengineRes> {
    const { address, engineId, ...values } = microengineValues;

    return this.request.post(`/v1/accounts/${accountNumber}/microengines`, values);
  }

  claimUserMicroengine(
    accountNumber: number,
    microengineId: number
  ): AxiosPromise<MicroengineClaimRes> {
    return this.request.post(`/v1/accounts/${accountNumber}/microengines/${microengineId}/claim`);
  }

  verifyUserMicroengine(
    accountNumber: number,
    microengineId: number,
    verificationValues: IClaimUserMicroengineValues
  ): AxiosPromise<MicroengineRes> {
    return this.request.post(
      `/v1/accounts/${accountNumber}/microengines/${microengineId}/verify`,
      verificationValues
    );
  }

  updateUserMicroengine(
    accountNumber: number,
    microengineId: number,
    microengineValues: Partial<MicroengineValues>
  ): AxiosPromise<MicroengineRes> {
    return this.request.patch(
      `/v1/accounts/${accountNumber}/microengines/${microengineId}`,
      microengineValues
    );
  }

  archiveUserMicroengine(
    accountNumber: number,
    microengineId?: number
  ): AxiosPromise<MicroengineRes> {
    return this.request.post(`/v1/accounts/${accountNumber}/microengines/${microengineId}/archive`);
  }

  getDevelopmentCommunity(name: string): AxiosPromise<DevelopmentCommunityRes> {
    return this.request.get(`/v1/microengines/development-community/${name}`);
  }

  getDevelopmentCommunityResults(name: string): AxiosPromise<DevelopmentResultRes[]> {
    return this.request.get(`/v1/microengines/development-community/${name}/results`);
  }

  setDevelopmentCommunityResult(
    communityName: string,
    instanceId: string,
    data: Partial<DevelopmentResultRes>
  ): AxiosPromise<any> {
    return this.request.patch(
      `/v1/microengines/development-community/${communityName}/results/${instanceId}`,
      data
    );
  }

  getUserDevelopmentResults(accountNumber: number, microengine?: string | null): AxiosPromise<any> {
    return this.request.get(`/v1/accounts/${accountNumber}/microengines/development-results`, {
      params: { microengine },
    });
  }

  getUserWallets(accountNumber: number): AxiosPromise<WalletRes[]> {
    return this.request.get(`/v1/accounts/${accountNumber}/microengines/wallets`);
  }

  // ---------  Rewards  --------- //

  getRewardsWallet(): AxiosPromise<WalletRes> {
    return this.request.get(`/v2/rewards/wallet`);
  }

  getRewardsSummary(params: { start?: string; end?: string }): AxiosPromise<TelemetrySummaryRes> {
    return this.request.get('/v2/rewards/summary', { params });
  }

  getRewardsTransactions(params: {
    start?: string;
    end?: string;
  }): AxiosPromise<WalletTransactionRes[]> {
    return this.request.get('/v2/rewards/transactions', { params });
  }

  patchRewardsWallet(data: {
    withdrawalAddress?: string;
    withdrawalsLimit?: string;
  }): AxiosPromise<WalletRes> {
    return this.request.patch(`/v2/rewards/wallet`, data);
  }

  startWithdrawal(
    nonce: string,
    amount: string,
    fee: string,
    feeTimestamp?: string,
    feeSign?: string
  ): AxiosPromise {
    return this.request.post(`/v2/rewards/wallet/start-withdrawal`, {
      nonce,
      amount,
      fee,
      feeTimestamp,
      feeSign,
    });
  }

  enrollRewards(): AxiosPromise<WalletEnrollmentRes> {
    return this.request.post('/v2/rewards/enrollment');
  }

  getFees(amount: string): AxiosPromise<FeeRes> {
    return this.request.get('/v2/rewards/fees', { params: { amount } });
  }

  // ---------  Hunting  --------- //

  getRuleset(rulesetId: string): AxiosPromise<RulesetRes> {
    return this.request.get(`/v1/rule/${rulesetId}`).then(this.extractResults);
  }

  getAllRulesets(params?: PageQuery<Ruleset>): AxiosPromise<RulesetsRes> {
    return this.request.get(`/v1/rules`, { params }).then(this.canonicalizeResultPath);
  }

  validateRuleset(file: File, cancelToken?: CancelToken): AxiosPromise<RulesetValidationRes> {
    const data = new FormData();
    data.append('file', file);
    return this.request.post('/v1/rules/validate', data, { cancelToken });
  }

  createRuleset(values: RulesetValues): AxiosPromise<RulesetRes> {
    return this.request.post('/v1/rules', values).then(this.extractResults);
  }

  updateRuleset(id: string, values: RulesetValues): AxiosPromise<RulesetRes> {
    return this.request.put(`/v1/rules/${id}`, values).then(this.extractResults);
  }

  downloadRuleset(huntId: string, scanType: ScanType): AxiosPromise<RulesetDownloadRes> {
    return this.request.get(`/v1/scans/${scanType}/rule/${huntId}/download`);
  }

  deleteRuleset(id: string): AxiosPromise<RulesetRes> {
    return this.request.delete(`/v1/rules/${id}`);
  }

  getAllLiveHunts(all = true, params?: PageQuery<Hunt>): AxiosPromise<LiveHuntsRes> {
    return this.request
      .get(`/v1/scans/live?all=${all ? '1' : '0'}`, { params })
      .then(this.canonicalizeResultPath);
  }

  getAllHistoricalHunts(params?: PageQuery<Hunt>): AxiosPromise<HuntsRes> {
    return this.request.get(`/v1/scans/historical`, { params }).then(this.canonicalizeResultPath);
  }

  runLiveHunt(ruleId: string, ruleName: string): AxiosPromise<LiveHuntRes> {
    return this.request
      .post(`/v1/scans/live/rule/${ruleId}`, { ruleset_name: ruleName })
      .then(this.extractResults);
  }

  runHistoricalHunt(ruleId: string, ruleName: string): AxiosPromise<HuntRes> {
    return this.request
      .post(`/v1/scans/historical/rule/${ruleId}`, { ruleset_name: ruleName })
      .then(this.extractResults);
  }

  cancelHunt(scanType: ScanType, huntId: string): AxiosPromise<HuntRes> {
    return this.request.put(`/v1/scans/${scanType}/hunt/${huntId}/cancel`);
  }

  cancelLiveHunt(ruleId: string): AxiosPromise<LiveHuntRes> {
    return this.request.delete(`/v1/scans/live/rule/${ruleId}/stop`);
  }

  deleteHunt(huntId: string, scanType: ScanType): AxiosPromise<{}> {
    return this.request.delete(`/v1/scans/${scanType}/hunt/${huntId}`);
  }

  deleteHuntResult(resultId: string, scanType: ScanType): AxiosPromise<{}> {
    return this.request.delete(`/v1/scans/${scanType}/hunt/result/${resultId}`);
  }

  deleteMultipleHuntResult(resultIds: string[], scanType: ScanType): AxiosPromise<{}> {
    return this.request.delete(`/v1/scans/${scanType}/hunt/multiple/results`, {
      data: { resultIds },
    });
  }

  deleteMultipleHunt(huntIds: string[], scanType: ScanType): AxiosPromise<{}> {
    return this.request.delete(`/v1/scans/${scanType}/hunt`, { data: { huntIds } });
  }

  getHuntResult(
    huntResultId: string,
    scanType: ScanType,
    historicalResult?: string
  ): AxiosPromise<HuntRes> {
    return this.request
      .get(`/v1/scans/${scanType}/hunt/result/${huntResultId}`, {
        params: historicalResult && { historicalResult },
      })
      .then(this.extractResults);
  }

  getHuntResults(
    scanType: ScanType,
    huntId = 'active',
    params?: PageQuery<Hunt>
  ): AxiosPromise<HuntRes> {
    return this.request
      .get(`/v1/scans/${scanType}/rule/${huntId}/results`, { params })
      .then(this.canonicalizeResultPath);
  }

  // ---------  Tags  --------- //

  getEmergingThreats(params: { threats?: number; families?: number }): AxiosPromise<ThreatsRes> {
    return this.request.get('/v1/tags/threats', { params });
  }

  /* ----------  Integrations  ---------- */

  getIntegrations(): AxiosPromise<IntegrationRes[]> {
    return this.request.get(`/v2/integrations`);
  }

  createIntegration(integrationData: Partial<Integration>): AxiosPromise<IntegrationRes> {
    return this.request.post(`/v2/integrations`, integrationData);
  }

  archiveIntegration(integrationId: string): AxiosPromise<IntegrationRes> {
    return this.request.delete(`/v2/integrations/${integrationId}`);
  }

  // ---------  Reports  --------- //

  generateReport(data: ReportInput): AxiosPromise<ReportRes> {
    return this.request.post(`/v3/reports`, data);
  }

  getReport(reportId: string): AxiosPromise<ReportRes> {
    return this.request.get(`/v3/reports/${reportId}`);
  }

  createTemplate(data: TemplateInput): AxiosPromise<TemplateRes> {
    return this.request.post(`/v3/reports/templates`, data);
  }

  getTemplate(templateId: string): AxiosPromise<TemplateRes> {
    return this.request.get(`/v3/reports/templates/${templateId}`);
  }

  getDefaultTemplate(): AxiosPromise<PaginatedTemplateRes> {
    return this.request.get(`/v3/reports/templates?is_default=true`);
  }

  editTemplate(templateId: string, data: Partial<TemplateInput>): AxiosPromise<TemplateRes> {
    return this.request.patch(`/v3/reports/templates/${templateId}`, data);
  }

  deleteTemplate(templateId: string): AxiosPromise<TemplateRes> {
    return this.request.delete(`/v3/reports/templates/${templateId}`);
  }

  async uploadTemplateLogo(
    file: File,
    id: string
  ): Promise<{ data: TemplateRes; status?: string; message?: string }> {
    return this.request.post(`/v3/reports/templates/logo/${id}`, await fileToBinary(file), {
      headers: {
        'Content-Type': file.type,
      },
    });
  }

  getTempalteLogo(url: string): Promise<string> {
    const response = this.request.get(url, { responseType: 'blob' });
    return response.then((res) => {
      const reader = new FileReader();
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result as string);
        };
        reader.readAsDataURL(res.data);
      });
    });
  }

  deleteTemplateLogo(id: string): AxiosPromise<TemplateRes> {
    return this.request.delete(`/v3/reports/templates/logo/${id}`);
  }
}

export default new ApiService();
