import type { Config } from 'tenants/config';
import { ReactComponent as AccentureLogo } from 'assets/tenants/accenture/accenture.svg';
import { fonts } from './fonts';

const config: Config = {
  name: 'Accenture',
  title: 'Accenture',
  description:
    'Accenture embraces the power of change to create 360° value & shared success in the U.S. for our clients, people, shareholders, partners and communities',
  author: '@Accenture',
  image:
    'https://dynamicmedia.accenture.com/is/image/accenture/Let%20There%20Be%20Change_Metadata_400x400?qlt=85&ts=1705925739315&dpr=off',
  assets: {
    logo: AccentureLogo,
    favicon:
      'https://www.accenture.com/content/dam/accenture/final/images/icons/symbol/Acc_GT_Dimensional_RGB.svg',
  },
  landingPage: 'scan',
  notLoggedInLandingPage: 'landing',
  isLogingRequired: true,
  enabledPages: 'all',
  disabledPages: [
    'pricing',
    'company',
    'docs',
    'status',
    'accessibility',
    'engine-agreement',
    'how-it-works',
    'marketplace-stats',
    'hunt',
    'engines',
    'wallets',
    'rewards',
    'webhooks',
    'team-wallets',
    'team-webhooks',
    'team-rewards',
  ],
  publicPages: ['terms', 'privacy'],
  navbarPages: ['scan', 'search', 'sandbox'],
  tabsEnabled: {
    search: ['hash', 'ioc'],
    sandbox: 'all',
  },
  disabledUsageFeatures: [
    'liveHunts',
    'taxii',
    'historicalHunts',
    'huntingYaraRules',
    'tagsView',
    'overageSupport',
    'metadataSearches',
  ],
  // docsUrl: 'https://www.accenture.com/', // TODO: add docs url here
  theme: {
    colors: {
      isPolySwarm: false, // Hide PolySwarm logo in some places
      purple: '#460073',
      lightPurple: '#7500C0',
      xLightPurple: '#dcafff',
      green: '#be82ff',
      lightBlue: '#B455AA',
    },
    fonts: fonts,
    font: {
      family: {
        main: 'Graphik',
        heading: 'Graphik',
        mono: 'Menlo, monospace',
      },
    },
  },
};

export default config;
