import React, { useState, Fragment } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import styled from '@emotion/styled';
import { defineMessages, useIntl, FormattedMessage } from 'react-intl';
import styles from 'views/styles';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { useUser } from 'views/components/providers/UserProvider';
import { btnMessages } from 'views/components/Button';
import ActionBtn from '../ActionBtn';

interface IDeleteApiKeyDialog {
  onDeleteApiKey: () => void;
}

const ContentWrapper = styled.div`
  width: 548px;
  padding: ${styles.spacing.md};

  & .cancelBtn {
    margin-left: 1rem;
  }
`;

const messages = defineMessages({
  header: {
    id: 'account.apiKeys.deleteApiKey.header',
    defaultMessage: 'Delete API Key',
  },
  text: {
    id: 'account.apiKeys.deleteApiKey.text',
    defaultMessage: 'Are you sure you want to delete the API key?',
  },
  textMfa: {
    id: 'account.apiKeys.deleteApiKey.text.mfa',
    defaultMessage:
      "If so, you'll now be redirected to the PolySwarm authO login page to enter your two factor authentication " +
      'to confirm the new configuration.',
  },
});

export const DeleteApiKeyDialog = ({ onDeleteApiKey }: IDeleteApiKeyDialog) => {
  const intl = useIntl();
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);
  const { mfaEnabled } = useUser();

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <ActionBtn
        name='delete'
        aria-label={intl.formatMessage(btnMessages.delete)}
        onClick={onOpen}
        data-cy='apiKeysDeleteBtn'
      />
      <Dialog open={open} onClose={onClose}>
        <ContentWrapper>
          <div css={classes.header}>{intl.formatMessage(messages.header)}</div>
          <div css={classes.subtitle}>
            <p css={classes.text} className='p'>
              {intl.formatMessage(messages.text)}
            </p>
            {mfaEnabled && (
              <p css={classes.text} className='p'>
                {intl.formatMessage(messages.textMfa)}
              </p>
            )}
          </div>
          <div css={classes.buttonsContainer}>
            <Button
              type='button'
              color='secondary'
              variant='contained'
              onClick={onDeleteApiKey}
              data-testid='submitBtn'>
              <FormattedMessage
                id='account.apiKeys.deleteApiKey.submitBtn'
                defaultMessage='Delete'
              />
            </Button>
            <Button
              className='cancelBtn'
              color='primary'
              variant='outlined'
              onClick={onClose}
              data-testid='cancelBtn'>
              <FormattedMessage {...btnMessages.cancel} />
            </Button>
          </div>
        </ContentWrapper>
      </Dialog>
    </Fragment>
  );
};

const useStyles = makeStyles({
  base: {
    header: {
      textAlign: 'center',
      fontSize: '2.6rem',
      fontWeight: 'bold',
      paddingBottom: styles.spacing.xs,
    },
    subtitle: {
      paddingBottom: styles.spacing.xs,
    },
    text: {
      paddingBottom: styles.spacing.tiny,
      textAlign: 'center',
    },
    buttonsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  light: {
    header: {
      color: styles.color.black,
    },
  },
  dark: {
    header: {
      color: styles.color.white,
    },
  },
});
