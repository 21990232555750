import React, { MouseEvent, ReactNode, useState } from 'react';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

export default function Popover({
  isOpen,
  button,
  content,
  placement = 'bottom-start',
  children,
  onClickAway,
}: {
  onClickAway?: () => void;
  isOpen?: boolean;
  button: ReactNode;
  content?: ReactNode;
  children?: ReactNode;
  placement?: 'bottom-start' | 'bottom-end' | 'top-start' | 'top-end';
}) {
  const ref = React.useRef(null);
  const [open, setOpen] = useState(false);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setOpen(!open);
  };

  const handleClickAway = () => {
    setOpen(false);
    onClickAway && onClickAway();
  };

  const anchorElement = ref?.current;

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <div
          ref={ref}
          aria-describedby='simple-popper'
          onClick={onClickAway === undefined ? handleClick : onClickAway}
        >
          {button}
        </div>
        <Popper
          placement={placement}
          id='simple-popper'
          open={isOpen === undefined ? open : isOpen}
          anchorEl={anchorElement}
        >
          {content ?? children}
        </Popper>
      </div>
    </ClickAwayListener>
  );
}
