import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import Settings from 'views/components/Settings';

interface IAdvancedSettings {
  isPasswordUser?: boolean;
  isMfaEnabled: boolean;
  onMfaToggle: (event: React.ChangeEvent, checked: boolean) => void;
  onArchiveUser: () => void;
}

const messages = defineMessages({
  mfaHeading: {
    id: 'account.advanced.mfa.heading',
    defaultMessage: 'Secure Authentication',
  },
  mfaText: {
    id: 'account.advanced.mfa.text',
    defaultMessage: 'Enable Two-Factor Authentication (2FA) for Secure Login',
  },
  archiveHeading: {
    id: 'account.advanced.archive.heading',
    defaultMessage: 'Delete Account',
  },
  archiveText: {
    id: 'account.advanced.archive.text',
    defaultMessage: 'Permanently delete your PolySwarm account and information',
  },
  archiveButton: {
    id: 'account.advanced.archive.button',
    defaultMessage: 'Delete Account',
  },
});

const AdvancedSettings = ({ isMfaEnabled, onMfaToggle, onArchiveUser }: IAdvancedSettings) => {
  const intl = useIntl();
  return (
    <Settings
      groups={[
        {
          heading: intl.formatMessage(messages.mfaHeading),
          isShown: true,
          settings: [
            {
              text: intl.formatMessage(messages.mfaText),
              action: <Switch onChange={onMfaToggle} checked={isMfaEnabled} data-cy='mfaBtn' />,
            },
          ],
        },
        {
          heading: intl.formatMessage(messages.archiveHeading),
          danger: true,
          settings: [
            {
              text: intl.formatMessage(messages.archiveText),
              action: (
                <Button
                  color='secondary'
                  variant='contained'
                  onClick={onArchiveUser}
                  data-cy='archiveUserBtn'
                >
                  {intl.formatMessage(messages.archiveButton)}
                </Button>
              ),
            },
          ],
        },
      ]}
    />
  );
};

export default AdvancedSettings;
