import React, { useState } from 'react'; // eslint-disable-line
import { jsx } from '@emotion/react'; /** @jsx jsx */ /** @jsxRuntime classic */
import { makeStyles } from 'views/components/providers/ThemeProvider';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

interface IDatePicker {
  label: string;
  value: Date | null;
  disabled?: boolean;
  minDate?: Date;
  onChange: (date: Date | null) => void;
}

export const DatePicker = ({ label, value, disabled, minDate, onChange }: IDatePicker) => {
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);
  const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        css={classes.datePicker}
        disableToolbar
        disabled={disabled}
        variant='inline'
        format={'MM/dd/yyyy'}
        margin='normal'
        helperText={'MM/DD/YYYY UTC'}
        id='date-picker-inline'
        label={label}
        inputVariant='outlined'
        value={value}
        minDate={minDate || tomorrow}
        initialFocusedDate={minDate || tomorrow}
        onChange={(e) => {
          onChange(e);
          handleClose();
        }}
        KeyboardButtonProps={{
          'aria-label': label,
        }}
        open={open}
        onOpen={handleOpen}
        onClose={handleClose}
      />
    </MuiPickersUtilsProvider>
  );
};

const useStyles = makeStyles({
  base: {
    datePicker: {},
  },
  light: {},
  dark: {},
});
