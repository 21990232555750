import { useState, Fragment } from 'react';
import { jsx } from '@emotion/react'; /** @jsxRuntime classic */ /** @jsx jsx */
import { object as yupObject, date as yupDate, string as yupString } from 'yup';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { Tooltip } from '@material-ui/core';
import styled from '@emotion/styled';
import { Formik, FormikProps } from 'formik';
import { defineMessages, useIntl, FormattedMessage } from 'react-intl';
import Checkbox from '@material-ui/core/Checkbox';
import { toISODate } from 'utils/date/date';
import { ApiKeyForm } from 'models/ApiKey';
import styles from 'views/styles';
import { makeStyles } from 'views/components/providers/ThemeProvider';
import { btnMessages } from 'views/components/Button';
import FabButton from 'views/components/Button/FabButton';
import { useUser } from 'views/components/providers/UserProvider';
import FormSelect from 'views/components/form/FormSelect';
import FormInput from 'views/components/form/FormInput';
import { DatePicker } from '../ApiKeyTable/DatePicker';

interface IAPIKeyFormValues {
  name: string;
  teamAccountNumber: string | number;
  expiration: Date | null;
  neverExpires: boolean;
}

interface ICreateApiKeyDialog {
  createApiKey: (apiKeyData: Partial<ApiKeyForm>) => void;
  team?: number;
}

const messages = defineMessages({
  title: {
    id: 'account.apiKeys.createApiKey.type.label',
    defaultMessage: 'Create {type} API Key',
  },
  name: {
    id: 'account.apiKeys.createApiKey.name.label',
    defaultMessage: 'Name',
  },
  selectTeam: {
    id: 'account.apiKeys.createApiKey.selectTeam.label',
    defaultMessage: 'Select team',
  },
  mfaMessage: {
    id: 'account.apiKeys.createApiKey.account.mfaMessage',
    defaultMessage:
      'Pressing "Save" you\'ll be redirected to the PolySwarm authO login page to enter your two factor authentication ' +
      'to confirm the new API Key.',
  },
});

const ContentWrapper = styled.div`
  width: 548px;
  padding: ${styles.spacing.md};
`;

const Buttons = styled.div`
  margin-top: 1rem;
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;

  *:first-of-type {
    margin-right: 5px !important;
  }

  *:last-of-type {
    margin-left: 5px !important;
  }
`;

const StyledButton = styled(Button)`
  flex: 1 1 auto;
`;

const schema = yupObject({
  expiration: yupDate().min(new Date()).max(new Date('9999-12-12')).nullable(),
  name: yupString().required(),
});

export const CreateApiKeyDialog = ({ createApiKey, team }: ICreateApiKeyDialog) => {
  const intl = useIntl();
  const user = useUser();
  const { classes } = useStyles();

  const [open, setOpen] = useState(false);
  const _onClick = () => {
    setOpen(true);
  };

  const _onClose = () => {
    setOpen(false);
  };

  const _onSubmit = (values: IAPIKeyFormValues) => {
    const teamAccountNumber = parseInt(String(values.teamAccountNumber), 10);
    const { neverExpires, ...formValues } = values;
    const data = {
      ...formValues,
      teamAccountNumber: teamAccountNumber === -1 ? undefined : teamAccountNumber,
      expiration: neverExpires ? null : toISODate(formValues.expiration || new Date()),
    };
    createApiKey(data);
  };

  return (
    <Fragment>
      <Tooltip title={`Add New ${team ? 'Team' : 'Personal'} API Key`} placement='top'>
        <div>
          <FabButton icon='plus' onClick={_onClick} testId='createApiKeyBtn' />
        </div>
      </Tooltip>
      <Dialog open={open} onClose={_onClose}>
        <ContentWrapper>
          <div css={classes.header}>
            {intl.formatMessage(messages.title, { type: team ? 'Team' : 'Personal' })}
          </div>

          <Formik
            validationSchema={schema}
            onSubmit={_onSubmit}
            initialValues={{
              teamAccountNumber: team || -1,
              name: '',
              expiration: null,
              neverExpires: false,
            }}
          >
            {(formik: FormikProps<IAPIKeyFormValues>) => {
              return (
                <form onSubmit={formik.handleSubmit}>
                  <FormInput
                    name='name'
                    placeholder={intl.formatMessage(messages.name)}
                    label={intl.formatMessage(messages.name)}
                  />
                  {team ? (
                    <div css={classes.selectTeam}>
                      <FormSelect
                        name='teamAccountNumber'
                        selected={formik.values.teamAccountNumber}
                        label={intl.formatMessage(messages.selectTeam)}
                        options={[
                          ...user.teams.map((item) => ({
                            value: item.accountNumber,
                            label: item.name,
                          })),
                        ]}
                      />
                    </div>
                  ) : null}
                  <div css={classes.expiresContainer}>
                    <DatePicker
                      label='Expiration Date'
                      disabled={formik.values.neverExpires}
                      value={formik.values.expiration}
                      onChange={(value) => {
                        formik.setFieldValue('expiration', value ?? null);
                      }}
                    />
                    <div css={classes.expiresCheckbox}>
                      <Checkbox
                        disabled={false}
                        color='primary'
                        checked={formik.values.neverExpires}
                        onChange={(value) => {
                          formik.setFieldValue('neverExpires', value.target.checked);
                        }}
                      />
                      <span css={classes.neverExpiresLabel}>Never Expire</span>
                    </div>
                  </div>
                  {user.mfaEnabled && (
                    <p css={classes.mfaMessage}>{intl.formatMessage(messages.mfaMessage)}</p>
                  )}
                  <Buttons>
                    <StyledButton
                      data-cy='submitBtn'
                      className='h-mt-xs'
                      color='primary'
                      variant='contained'
                      type='submit'
                      disabled={
                        !(
                          formik.dirty &&
                          formik.isValid &&
                          formik.values.name &&
                          (formik.values.expiration || formik.values.neverExpires)
                        )
                      }
                    >
                      <FormattedMessage {...btnMessages.save} />
                    </StyledButton>
                    <StyledButton
                      data-cy='cancelBtn'
                      className='h-mt-xs'
                      color='primary'
                      variant='outlined'
                      onClick={_onClose}
                    >
                      <FormattedMessage {...btnMessages.cancel} />
                    </StyledButton>
                  </Buttons>
                </form>
              );
            }}
          </Formik>
        </ContentWrapper>
      </Dialog>
    </Fragment>
  );
};

const useStyles = makeStyles({
  base: {
    expiresContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: '3rem 0px 0px',
    },
    header: {
      textAlign: 'left',
      fontSize: '2.6rem',
      fontWeight: 'bold',
      paddingBottom: styles.spacing.xs,
    },
    mfaMessage: {
      textAlign: 'center',
      paddingTop: styles.spacing.sm,
    },
    neverExpiresLabel: {
      fontWeight: 'bold',
    },
    expiresCheckbox: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      minWidth: 'fit-content',
    },
    selectTeam: {
      marginTop: '3rem',
    },
  },
  light: {
    header: {
      color: styles.color.black,
    },
  },
  dark: {
    header: {
      color: styles.color.white,
    },
  },
});
